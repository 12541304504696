/* eslint-disable import/prefer-default-export */
import humps from 'humps';
import { AppDispatch } from 'redux/store';
import { Cadence } from 'redux/schemas/models/mentoring-program-sessions';
import PusherService from 'shared/services/pusher-service';
import moment from 'moment';
import t from 'react-translate';
import { getISOString } from 'athena/components/mentoring-program/home/participant/session-edit-mode/session-edit-mode';
import { Event, EventList } from './event-types';

export const configureChannel = (
  channelName: string,
  events: EventList,
  dispatch: AppDispatch,
) => {
  const pusherChannel = PusherService.setupChannel(channelName);

  events.forEach(({ name, handler }) => pusherChannel.bind(name, (event: Event) => {
    const camelizedEvent = humps.camelizeKeys(event) as Event;
    handler(camelizedEvent, dispatch);
  }));

  return () => {
    events.forEach(({ name, handler }) => pusherChannel.unbind(name, handler));
    PusherService.removeChannel(channelName);
  };
};

export const getDayOfWeekAndNthWeek = (startDate: string) => {
  const start = moment(startDate);
  const dayOfWeek = start.format('dddd');
  const dayOfMonth = start.date();

  let nthWeek: string;
  if (dayOfMonth <= 7) {
    nthWeek = 'FIRST';
  } else if (dayOfMonth <= 14) {
    nthWeek = 'SECOND';
  } else if (dayOfMonth <= 21) {
    nthWeek = 'THIRD';
  } else if (dayOfMonth <= 28) {
    nthWeek = 'FOURTH';
  } else {
    nthWeek = 'LAST';
  }

  return { dayOfWeek, nthWeek };
};

const getProgramText = ({
  nthWeek,
  endDate,
  closureDate,
  hasClosureDate,
  translations,
}: {
  nthWeek: string,
  endDate: string,
  closureDate: string,
  hasClosureDate: boolean,
  translations: any,
}) => {
  const shouldShowClosureText = hasClosureDate && getISOString(closureDate) === getISOString(endDate);
  const programClosureText = shouldShowClosureText ? translations.PROGRAM_CLOSURE_DATE() : '';
  const nthWeekText = translations.NTH_WEEK[nthWeek]();
  let endDateText = '';

  if (endDate) {
    endDateText = moment(endDate).format('MMMM D, YYYY');
  } else if (closureDate) {
    endDateText = moment(closureDate).format('MMMM D, YYYY');
  }
  return { endDateText, programClosureText, nthWeekText };
};

const getCadenceText = ({
  cadence,
  translations,
  programClosureText,
  dayOfWeek,
  endDateText,
  nthWeekText,
}: {
  cadence: Cadence,
  translations: any,
  programClosureText: string,
  dayOfWeek: string,
  endDateText: string,
  nthWeekText: string,
}): string => {
  if (!cadence) {
    return '';
  }
  switch (cadence) {
    case Cadence.WEEKLY:
      return translations.WEEKLY(programClosureText, dayOfWeek, endDateText);
    case Cadence.BI_WEEKLY:
      return translations.BI_WEEKLY(programClosureText, dayOfWeek, endDateText);
    case Cadence.MONTHLY:
      return translations.MONTHLY(programClosureText, dayOfWeek, endDateText, nthWeekText);
    default:
      return translations.DEFAULT(dayOfWeek, endDateText || programClosureText);
  }
};

type PreviewTextParams = {
  cadence: Cadence;
  startDate: string;
  endDate?: string;
  closureDate?: string;
};

export const getPreviewText = ({
  cadence,
  startDate,
  endDate,
  closureDate,
}: PreviewTextParams): string => {
  const { dayOfWeek, nthWeek } = getDayOfWeekAndNthWeek(startDate);
  const translations = t.MENTORING_PROGRAMS.PARTICIPANT_HOME.PLAN_SESSION_FLYOUT.EDIT_MODE.PREVIEW_TEXT;
  const newTranslations = translations.NEW;

  const {
    endDateText,
    programClosureText,
    nthWeekText,
  } = getProgramText({
    nthWeek,
    endDate,
    closureDate,
    hasClosureDate: !!closureDate,
    translations,
  });

  return getCadenceText({
    cadence,
    translations: newTranslations,
    programClosureText,
    dayOfWeek,
    endDateText,
    nthWeekText,
  });
};

type RecurrenceUpdateParams = {
  cadence?: Cadence;
  startDate: string;
  endDate?: string;
  closureDate?: string;
  hasClosureDate?: boolean;
  cadenceChanged?: boolean;
  endDateChanged?: boolean;
};

export const getRecurrenceUpdatePreview = ({
  cadence,
  startDate,
  endDate,
  closureDate,
  hasClosureDate,
  cadenceChanged,
  endDateChanged,
}: RecurrenceUpdateParams): string => {
  const { dayOfWeek, nthWeek } = getDayOfWeekAndNthWeek(startDate);
  const translations = t.MENTORING_PROGRAMS.PARTICIPANT_HOME.PLAN_SESSION_FLYOUT.EDIT_MODE.PREVIEW_TEXT;
  const editTranslations = translations.EDIT;

  const {
    endDateText,
    programClosureText,
    nthWeekText,
  } = getProgramText({
    nthWeek,
    endDate,
    closureDate,
    hasClosureDate,
    translations,
  });

  if (endDateChanged && !cadenceChanged && !hasClosureDate) {
    return editTranslations.ONLY_END_DATE(endDateText);
  }

  if (cadenceChanged) {
    return getCadenceText({
      cadence,
      translations: editTranslations,
      programClosureText,
      dayOfWeek,
      endDateText,
      nthWeekText,
    });
  }

  return '';
};
