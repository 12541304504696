import React from 'react';
import { css } from '@emotion/react';

import ClickableContainer from 'components/clickable-container';
import { semiBoldFontWeight } from 'styles/global_defaults/fonts';
import { shadedWhite } from 'athena/styles/colors';
import { gray1, gray2 } from 'styles/global_defaults/colors';
import { largeSpacing, quarterSpacing } from 'styles/global_defaults/scaffolding';

const PILL_HEIGHT = largeSpacing + quarterSpacing;

type Props = {
  text: string;
  selected: boolean;
  className?: string;
  onClick: () => void;
  dataQa?: string;
  dataQaId?: string;
};

const PillButton = (props: Props) => {
  const { text, selected, className, onClick, dataQa, dataQaId } = props;

  const styles = css`
    color: ${gray2};
    height: ${PILL_HEIGHT}px;
    background-color: ${shadedWhite};
    font-weight: ${semiBoldFontWeight};
    border-radius: ${PILL_HEIGHT / 2}px;

    &:hover {
      color: ${gray1};
      border: 1px solid ${gray1};
    }

    ${selected && css`
      color: ${gray1};
      background-color: transparent;
      border: 1px solid ${gray1};
    `};
  `;

  return (
    <ClickableContainer
      css={styles}
      data-qa={dataQa}
      data-qa-id={dataQaId}
      onClick={onClick}
      aria-label={text}
      className={`pl-4 pr-4 align-items-center justify-content-center text-medium ${className ?? ''}`}
    >
      {text}
    </ClickableContainer>
  );
};

export default PillButton;
