import { useContext } from 'react';
import { config } from '@config/pendo.config.json';
import { MentorshipProgramSession } from 'redux/schemas/models/mentoring-program-sessions';

// Styles
import { css } from '@emotion/react';
import { gray2, gray4, gray7 } from 'styles/global_defaults/colors';

// View Mode Components
import ClickableContainer from 'components/clickable-container';
import SessionTimeViewMode from './session-time-view-mode';
import MeetingLinkViewMode from './meeting-link-view-mode';
import LocationViewMode from './location-view-mode';
import EditIconViewMode from './edit-icon-view-mode';

import MentoringProgramContext from '../../../context';

type SessionScheduleInfoViewModeProps = {
  session: MentorshipProgramSession;
  onClick: () => void;
};

const styles = css`
  .session-info {
    flex: 1;
  }

  &:hover {
    .session-edit .icon {
      pointer: cursor;
      border-radius: 50%;
      background-color: ${gray7};
      color: ${gray2};
    }
  }

  .session-edit .icon, .recurring-icon {
    color: ${gray4};
  }
`;

const ScheduleInfoCard = (props: SessionScheduleInfoViewModeProps) => {
  const { session, onClick } = props;
  const { timeZone } = useContext(MentoringProgramContext);
  const {
    id,
    title,
    scheduledDate,
    location,
    meetingLink,
    duration,
    integrationType,
    mentorshipProgramSessionPlanId,
  } = session;

  const handleClick = (e) => {
    // This prevents the onClick from being called when the meeting link is clicked
    if (!e.target.classList.contains('meeting-link-text')) {
      onClick();
    }
  };

  return (
    <ClickableContainer
      css={styles}
      className='session-schedule-info view-mode d-flex flex-row w-100 pb-2'
      onClick={handleClick}
      data-qa={config.pendo.athena.mentorshipProgram.sessionInfo}
      data-qa-id={`${config.pendo.athena.mentorshipProgram.sessionInfo}_${id ?? '1'}`}
    >
      <div className='d-flex session-info flex-column pr-2'>
        <div className='heading-4 pb-1 pt-4'>{title}</div>
        <SessionTimeViewMode
          date={scheduledDate}
          timeZone={timeZone}
          duration={duration}
          isRecurring={!!mentorshipProgramSessionPlanId}
        />
        {meetingLink && (
          <MeetingLinkViewMode
            link={meetingLink}
            integrationType={integrationType}
          />
        )}
        {location && (
          <LocationViewMode location={location} />
        )}
      </div>
      <EditIconViewMode />
    </ClickableContainer>
  );
};

export default ScheduleInfoCard;
