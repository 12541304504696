import MentoringProgramContext from 'athena/components/mentoring-program/context';
import moment from 'moment';
import { useContext, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import t from 'react-translate';
import { MentorshipProgramSession } from 'redux/schemas/models/mentoring-program-sessions';
import NvDatePicker, { DatePickerType } from 'shared/components/inputs/nv-datepicker';
import NvDropdown, { NvDropdownOption, NvDropdownButtonStyle } from 'shared/components/inputs/nv-dropdown';
import { durationText } from 'shared/utils';
import { range } from 'underscore';
import MeetingPlanDetails from './meeting-plan-details';

type SessionScheduleRecurrenceProps = Pick<MentorshipProgramSession, 'duration'> & {
  isOptional: boolean;
  showMeetingPlan: boolean;
};

const SessionScheduleRecurrence = (props: SessionScheduleRecurrenceProps) => {
  const {
    duration,
    isOptional = false,
    showMeetingPlan = false,
  } = props;
  const translations = t.MENTORING_PROGRAMS.PARTICIPANT_HOME.PLAN_SESSION_FLYOUT.EDIT_MODE;
  const durations = range(12).map((val) => (val + 1) * 30);
  const { timeZone } = useContext(MentoringProgramContext);
  const { setValue } = useFormContext();

  const durationItems = useMemo(() => (
    durations.map((d) => ({
      id: d,
      type: 'text',
      text: durationText(d),
      callback: () => setValue('duration', d, { shouldDirty: true }),
    })) as NvDropdownOption[]
  ), [setValue, durations]);

  const timeZoneItems = useMemo(() => ([{
    id: timeZone.ianaTimeZone,
    type: 'text',
    text: timeZone.timeZoneAbbreviation,
  }] as NvDropdownOption[]), [timeZone]);

  const durationIndex = useMemo(() => {
    const index = durations.indexOf(duration);
    return index === -1 ? 0 : index;
  }, [duration, durations]);

  return (
    <div className='recurrence-info pt-4'>
      {!isOptional && showMeetingPlan && (
        <div className='heading-4 pb-4'>
          {translations.RECURRENCE_TITLE()}
        </div>
      )}
      <div className='d-flex align-items-center session-date-time mb-4'>
        <div className='session-next-meeting pr-2'>
          <div className='session-time mb-1'>
            {(isOptional || !showMeetingPlan) ? translations.DATE_AND_TIME() : translations.NEXT_MEETING()}
          </div>
          <NvDatePicker
            type={DatePickerType.DATETIME}
            withForm
            name='scheduledDate'
            placement='top-start'
            highlightDates={[moment().startOf('day')]}
            min={moment()}
            required
          />
        </div>
        <div className='session-timezone pr-2'>
          <div className='session-time mb-1'>{translations.TIMEZONE()}</div>
          <NvDropdown
            showSelectedIndicator
            initialIndex={0}
            items={timeZoneItems}
            menuClassName='w-100'
            buttonStyle={NvDropdownButtonStyle.FORM_SMALL}
            disabled
          />
        </div>
        <div className='session-duration'>
          <div className='session-time mb-1'>{translations.DURATION()}</div>
          <NvDropdown
            initialIndex={durationIndex}
            showSelectedIndicator
            items={durationItems}
            buttonStyle={NvDropdownButtonStyle.FORM_SMALL}
            menuClassName='w-100'
            minWidth={120}
          />
        </div>
      </div>
      {showMeetingPlan && <MeetingPlanDetails isOptional={isOptional} />}
    </div>
  );
};

export default SessionScheduleRecurrence;
