import { MentoringProgramActionItem } from './mentoring-program-action-items';
import { MentorshipProgramConnection } from './mentorship-program-connections';

export enum IntegrationType {
  ZOOM = 'Zoom',
  MS_TEAMS = 'MS Teams',
}

export enum Cadence {
  WEEKLY = 'weekly',
  BI_WEEKLY = 'bi_weekly',
  MONTHLY = 'monthly',
  DEFAULT = '',
}

export type MentorshipProgramMeetingPlan = {
  id: number;
  title: string;
  details?: string;
  startDate: string | moment.Moment;
  mentorshipProgramSessions: MentorshipProgramSession[];
  location?: string;
  duration?: number;
  cadence: Cadence;
  endDate?: string | moment.Moment;
  // TODO: Check names with backend
  meetingLink?: string;
  integrationType?: IntegrationType;
  isLoading?: boolean;
};

export type MentorshipProgramSession = {
  id: number;
  title: string;
  details?: string;
  // consider checking with backend to change this
  status: 'past' | 'upcoming';
  scheduledDate: string | moment.Moment;
  mentorshipProgramConnection: MentorshipProgramConnection;
  mentorshipProgramActionItems: MentoringProgramActionItem[];
  location?: string;
  duration?: number; // in minutes
  mentorshipProgramSessionPlanId?: number;
  // TODO: Check names with backend
  meetingLink?: string;
  integrationType?: IntegrationType;
};
