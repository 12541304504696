import { createSelector } from 'reselect';
import moment from 'moment';

import { RootState } from 'redux/schemas';
import { MentorshipProgramSession } from 'redux/schemas/models/mentoring-program-sessions';

export const getMentorshipProgramSessionsList = createSelector(
  (state: RootState) => state.models.mentorshipProgramSessions,
  (state, ids: number[]) => ids,
  (MentorshipProgramSessions: Record<number, MentorshipProgramSession>, ids: number[]) => ids.map((id) => MentorshipProgramSessions[id]),
);

export const getNextMentorshipProgramSession = (state: RootState, connectionId) => Object.values(state.models.mentorshipProgramSessions)
  .filter((session) => session.status === 'upcoming' && session.mentorshipProgramConnection.id === connectionId)
  .sort((sessionA, sessionB) => moment(sessionA.scheduledDate).diff(moment(sessionB.scheduledDate)))[0];

export const getResetMentoringProgramSessionsListState = (state: RootState) => state.app.mentoringProgram.sessions.resetList;

export const getMentorshipProgramSession = (sessionId: number) => (state: RootState) => state.models.mentorshipProgramSessions?.[sessionId];

export const getSessionActionItems = (sessionId: number) => (state: RootState) => {
  const session = state.models.mentorshipProgramSessions?.[sessionId];
  return session?.mentorshipProgramActionItems || [];
};

export const getMentorshipProgramSessionPlan = (connectionId: number) => (state: RootState) => (
  state.models.mentorshipProgramMeetingPlans?.[connectionId]
);
