import { getRecurrenceUpdatePreview } from 'athena/helpers/utils';
import { css } from '@emotion/react';
import { blue05 } from 'athena/styles/colors';
import { Cadence } from 'redux/schemas/models/mentoring-program-sessions';
import { getISOString } from './session-edit-mode';

type SessionUpdatePreviewTextProps = {
  scheduledDate: string;
  closureDate: string;
  endsOn: string;
  cadence: Cadence;
  cadenceChanged: boolean;
  endDateChanged: boolean;
};

const styles = css`
  background-color: ${blue05};
`;

const SessionUpdatePreviewText = (props: SessionUpdatePreviewTextProps) => {
  const {
    scheduledDate,
    closureDate,
    endsOn,
    cadence,
    cadenceChanged,
    endDateChanged,
  } = props;

  return (
    <div css={styles} className='p-2 d-flex justify-content-center align-items-center'>
      {getRecurrenceUpdatePreview({
        startDate: getISOString(scheduledDate),
        hasClosureDate: !!getISOString(closureDate),
        closureDate: getISOString(closureDate),
        endDate: getISOString(endsOn),
        cadenceChanged,
        endDateChanged,
        cadence,
      })}
    </div>
  );
};

export default SessionUpdatePreviewText;
