import { useEffect, useRef, useState } from 'react';
import t from 'react-translate';
import { config } from '@config/pendo.config.json';

import { MentorshipProgramSession } from 'redux/schemas/models/mentoring-program-sessions';

import AthenaTextInput from 'athena/components/text-input';
import VirtualMeeting from './virtual-meeting';

const shouldShowVirtualMeeting = (
  meetingLink: MentorshipProgramSession['meetingLink'],
  integrations: string[],
) => {
  if (meetingLink) return true;
  return integrations.length > 0;
};

type SessionScheduleLocationProps = Pick<
MentorshipProgramSession,
'id' | 'meetingLink' | 'integrationType'
> & {
  isEdit: boolean;
};

const SessionScheduleLocation = (props: SessionScheduleLocationProps) => {
  const {
    id,
    meetingLink,
    integrationType,
    isEdit,
  } = props;
  // TODO: Retrieve enabled integrations from the BE once the endpoint is available
  const [enabledIntegrations, setEnabledIntegrations] = useState([]);
  const inputRef = useRef(null);

  const translations = t.MENTORING_PROGRAMS.PARTICIPANT_HOME.PLAN_SESSION_FLYOUT.EDIT_MODE;
  const dataQATags = config.pendo.athena.mentorshipProgram.participantHome.sessions.planSessionFlyout;

  useEffect(() => {
    if (inputRef.current && !isEdit) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, []);

  return (
    <div className='location-info'>
      <div className='session-time mb-1'>{translations.TITLE()}</div>
      <div className='title'>
        <AthenaTextInput
          withForm
          name='title'
          maxLength={255}
          dataQa={dataQATags.sessionTitleInputField}
          ref={inputRef}
          required
        />
      </div>
      {shouldShowVirtualMeeting(meetingLink, enabledIntegrations) && (
        <VirtualMeeting
          integrationType={integrationType}
          meetingLink={meetingLink}
          enabledIntegrations={enabledIntegrations}
        />
      )}
      <div className='session-time mb-1'>{translations.LOCATION()}</div>
      <div className='location'>
        <AthenaTextInput
          withForm
          name='location'
          maxLength={190}
          dataQa={dataQATags.locationInputField}
          dataQaId={`${dataQATags.locationInputField}_${id ?? '0'}`}
        />
      </div>
    </div>
  );
};

export default SessionScheduleLocation;
