import { css } from '@emotion/react';
import {
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import { AngularContext } from 'react-app';
import t from 'react-translate';
import { useAppDispatch } from 'redux/store';
import CollapsiblePanel from 'athena/components/collapsible-panel';
import MentoringProgramContext from 'athena/components/mentoring-program/context';
import PillButton from 'athena/components/pill-button';
import ClickableContainer from 'components/clickable-container';
import { gray2, gray7 } from 'styles/global_defaults/colors';
import {
  semiBoldFontWeight,
  textLargeBodyFontSize,
} from 'styles/global_defaults/fonts';
import {
  extraLargeSpacing,
  halfSpacing,
  standardSpacing,
} from 'styles/global_defaults/scaffolding';
import {
  fetchMeetingPlan,
  getMentoringProgramSessions,
  getMentoringProgramSessionsCounts,
  resetMentoringProgramSessionsList,
} from 'redux/actions/mentoring-program-sessions';
import { MentorshipProgramSession } from 'redux/schemas/models/mentoring-program-sessions';
import useInfiniteScroll from 'shared/hooks/use-infinite-scroll';
import { usePaginatedApi } from 'shared/hooks/use-paginated-api';
import prodPathReplace from 'shared/prod-path-rewrite';
import {
  getMentorshipProgramSessionsList,
  getNextMentorshipProgramSession,
  getResetMentoringProgramSessionsListState,
} from 'redux/selectors/mentorship-program-sessions';
import { config } from '@config/pendo.config.json';
import NextSession from './next-session';
import SessionCard from './session-card';
import { FirstSeenKey, PanelStateKey } from 'athena/components/constants';
import SlideTransition, { Slide } from 'athena/components/slide-transition';
import StarsConfetti from 'athena/components/stars-confetti';

export const sessionDateFormat = 'ddd, MMMM D [at] h:mm a';

enum SessionFilters {
  PAST = 'past',
  UPCOMING = 'upcoming',
}

type SessionsListProps = {
  children?: React.ReactElement;
  showPlanSessionFlyout: (session?: MentorshipProgramSession) => void;
};

const SessionsList = ({
  children,
  showPlanSessionFlyout,
}: SessionsListProps) => {
  const styles = css`
    .empty-sessions {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: ${standardSpacing}px;

      border-radius: ${halfSpacing}px;
      background-color: ${gray7};
      color: ${gray2};

      img {
        height: ${extraLargeSpacing}px;
        width: ${extraLargeSpacing}px;
        margin-bottom: ${halfSpacing}px;
      }
    }

    .add-session-button {
      font-weight: ${semiBoldFontWeight};
      font-size: ${textLargeBodyFontSize}px;
    }

    .scroll-list {
      max-height: 515px;
      overflow-y: auto;
    }
  `;

  const dispatch = useAppDispatch();
  const { mentoringProgram } = useContext(MentoringProgramContext);
  const { injectServices } = useContext(AngularContext);
  const [$state] = injectServices(['$state']);

  const [filter, setFilter] = useState<SessionFilters>(SessionFilters.UPCOMING);
  const [pastSessionsCount, setPastSessionsCount] = useState(0);
  const [upcomingSessionsCount, setUpcomingSessionsCount] = useState(0);

  let connectionId = parseInt($state.params.connectionId, 10);
  const { mentorshipProgramEnrollment: enrollmentId } = mentoringProgram || {};
  const mentoringProgramEnrollment = useSelector(
    (state) => state.models.mentoringProgramEnrollments[enrollmentId]
  );
  const { firstConnectionAnnounced } = mentoringProgramEnrollment;
  if (!connectionId) {
    const { assignedConnections } = mentoringProgramEnrollment;

    connectionId = assignedConnections[0].connectionId;
  }

  const nextSession = useSelector((state) =>
    getNextMentorshipProgramSession(state, connectionId)
  );

  const scrollRef = useRef<HTMLDivElement>();
  const reachedEnd = useInfiniteScroll(scrollRef.current, 300);
  const resetList = useSelector(getResetMentoringProgramSessionsListState);

  const NEXT_SESSION_SEEN_KEY = `${FirstSeenKey.NEXT_SESSION_SEEN}-${enrollmentId}`;
  const SESSIONS_LIST_SEEN_KEY = `${FirstSeenKey.SESSIONS_LIST_SEEN}-${enrollmentId}`;

  const params = useMemo(
    () => ({
      pageSize: 20,
      filter,
    }),
    [filter]
  );

  const { result, loadMore, reset } = usePaginatedApi<any, any>(
    (p) =>
      dispatch(
        getMentoringProgramSessions({
          programId: mentoringProgram.id,
          connectionId,
          ...p,
        })
      ).then((action) => action.payload),
    params,
    getMentorshipProgramSessionsList
  );

  useEffect(() => {
    if (reachedEnd) {
      loadMore();
    }
  }, [loadMore, reachedEnd]);

  useEffect(() => {
    if (resetList) {
      reset();
      dispatch(resetMentoringProgramSessionsList({ resetList: false }));

      dispatch(
        getMentoringProgramSessionsCounts({
          programId: mentoringProgram.id,
          connectionId,
        })
      ).then((action) => {
        setPastSessionsCount(action.payload.past);
        setUpcomingSessionsCount(action.payload.upcoming);
      });
    }
  }, [dispatch, reset, resetList]);

  useEffect(() => {
    dispatch(
      getMentoringProgramSessionsCounts({
        programId: mentoringProgram.id,
        connectionId,
      })
    ).then((action) => {
      setPastSessionsCount(action.payload.past);
      setUpcomingSessionsCount(action.payload.upcoming);
    });
  }, []);

  const [showConfetti, setShowConfetti] = useState(false);

  const onSeen = () => {
    setShowConfetti(true);
    setTimeout(() => {
      setShowConfetti(false);
    }, 3000);
  };

  useEffect(() => {
    if (result) {
      const planId = result.find((item) => item.mentorshipProgramSessionPlanId)?.mentorshipProgramSessionPlanId;
      if (planId) {
        dispatch(
          fetchMeetingPlan({
            programId: mentoringProgram.id,
            mentorshipProgramSessionPlanId: planId,
            mentorshipProgramConnectionId: connectionId,
          })
        );
      }
    }
  }, [result]);

  const toSessionCard = (item, index) =>
    item ? (
      <ClickableContainer
        onClick={() => showPlanSessionFlyout(item)}
        className='mt-2'
        aria-label={t.MENTORING_PROGRAMS.PARTICIPANT_HOME.SESSIONS.ARIA_LABEL.VIEW_DETAILS(item.title)}
      >
        {index === 0 ? (
          <StarsConfetti showLeft={showConfetti} showRight={showConfetti}>
            <SessionCard title={item.title} time={item.scheduledDate} />
          </StarsConfetti>
        ) : (
          <SessionCard title={item.title} time={item.scheduledDate} />
        )}
      </ClickableContainer>
    ) : null;

  if (!firstConnectionAnnounced) {
    return null;
  }

  const buttonLabel = t.MENTORING_PROGRAMS.PARTICIPANT_HOME.SESSIONS.ADD_SESSION();
  const panelTitle = t.MENTORING_PROGRAMS.PARTICIPANT_HOME.SESSIONS.TITLE(
    pastSessionsCount + upcomingSessionsCount
  );

  return (
    <Fragment>
      <StarsConfetti showLeft={showConfetti} showRight={showConfetti}>
        <SlideTransition
          type={Slide.IN}
          seenKey={NEXT_SESSION_SEEN_KEY}
          onSeen={onSeen}
          showTransition={firstConnectionAnnounced}
        >
          <NextSession
            session={nextSession}
            showPlanSessionFlyout={showPlanSessionFlyout}
            className={showConfetti ? 'show-gradient' : ''}
          />
        </SlideTransition>
      </StarsConfetti>
      {children}
      <SlideTransition
        type={Slide.IN}
        seenKey={SESSIONS_LIST_SEEN_KEY}
        onSeen={onSeen}
        showTransition={firstConnectionAnnounced}
      >
        <CollapsiblePanel
          title={panelTitle}
          panelState={{
            persistState: true,
            localStorageKey: PanelStateKey.SESSIONS_LIST_STATE,
          }}
          dataQa={
            config.pendo.athena.mentorshipProgram.participantHome.sessions.list
              .list
          }
          ariaLabel={panelTitle}
          className={showConfetti ? 'show-gradient' : ''}
        >
          <div css={styles}>
            <div className='button-row d-flex align-items-center my-4'>
              <PillButton
                key={SessionFilters.UPCOMING}
                onClick={() => setFilter(SessionFilters.UPCOMING)}
                selected={filter === SessionFilters.UPCOMING}
                text={t.MENTORING_PROGRAMS.PARTICIPANT_HOME.SESSIONS.UPCOMING(
                  upcomingSessionsCount
                )}
                dataQa={
                  config.pendo.athena.mentorshipProgram.participantHome.sessions
                    .list.filterByUpcoming
                }
              />
              <PillButton
                key={SessionFilters.PAST}
                className='ml-2'
                onClick={() => setFilter(SessionFilters.PAST)}
                selected={filter === SessionFilters.PAST}
                text={t.MENTORING_PROGRAMS.PARTICIPANT_HOME.SESSIONS.PAST(
                  pastSessionsCount
                )}
                dataQa={
                  config.pendo.athena.mentorshipProgram.participantHome.sessions
                    .list.filterByPast
                }
              />
              <Button
                variant='link'
                onClick={() => showPlanSessionFlyout()}
                className='add-session-button ml-auto'
                data-qa={
                  config.pendo.athena.mentorshipProgram.participantHome.sessions
                    .list.showPlanSessionFlyout
                }
              >
                {buttonLabel}
              </Button>
            </div>
            <div className='scroll-list' ref={scrollRef}>
              {result?.map((item, index) => toSessionCard(item, index))}
            </div>
            {!result?.length && (
              <div className='empty-sessions'>
                <img src={prodPathReplace('images/no-sessions.svg')} alt='' />
                <div>
                  {filter === 'upcoming'
                    ? t.MENTORING_PROGRAMS.PARTICIPANT_HOME.SESSIONS.NO_UPCOMING_SESSIONS()
                    : t.MENTORING_PROGRAMS.PARTICIPANT_HOME.SESSIONS.NO_PAST_SESSIONS()}
                </div>
              </div>
            )}
          </div>
        </CollapsiblePanel>
      </SlideTransition>
    </Fragment>
  );
};

export default SessionsList;
