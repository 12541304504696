import t from 'react-translate';

import { css } from '@emotion/react';
import { almostWhite } from 'athena/styles/colors';
import { white } from 'styles/global_defaults/colors';

import { useContext } from 'react';
import { AngularContext } from 'react-app';

import { Button } from 'react-bootstrap';
import { ReactComponent as ProgramClosedPicture } from 'styles/icons/program-closed.svg';
import { config } from '@config/pendo.config.json';
import { withOverflowFlexGrowClass } from '../../constants';

const IMAGE_HEIGHT = 150;
const IMAGE_WIDTH = 146.42;

const withOverflowFlexGrowStyles = css`
  .${withOverflowFlexGrowClass} {
    flex: 1;
    min-height: 0;
  }
`;

export const errorStyles = (containerTranslate: number) => css`
  padding: 0;
  background-color: ${almostWhite};
  justify-content: center;
  .program-unavailable {
    width: 440px;
    max-height: 416px;
    height: calc(100% + ${120 - containerTranslate}px);
    transform: translateY(-${130 - containerTranslate}px);
    ${withOverflowFlexGrowStyles};
    background-color: ${white};
    border-radius: 10px;
    margin: 0 auto;
    .description {
      max-width: 350px;
      text-align: center;
    }
  }
}`;

const ProgramUnavailable = () => {
  const { injectServices } = useContext(AngularContext);
  const [$state] = injectServices(['$state']);

  const goToDashboard = () => $state.go('dashboard');

  const buttonLabel = t.MENTORING_PROGRAMS.PARTICIPANT_HOME.UNENROLLED.BACK_TO_DASHBOARD();

  return (
    <div className='program-unavailable d-flex flex-column align-items-center justify-content-center'>
      <ProgramClosedPicture
        height={IMAGE_HEIGHT}
        width={IMAGE_WIDTH}
      />
      <div className='page-title mt-6 mb-1'>
        {t.MENTORING_PROGRAMS.PARTICIPANT_HOME.UNENROLLED.TITLE()}
      </div>
      <div className='text-body-large description mb-6'>
        {t.MENTORING_PROGRAMS.PARTICIPANT_HOME.UNENROLLED.DESCRIPTION()}
      </div>
      <Button
        size='lg'
        variant='primary'
        onClick={goToDashboard}
        data-qa={
          config.pendo.athena.mentorshipProgram.participantHome.redirectToDashboard.programUnavailable
        }
        aria-label={buttonLabel}
      >
        {buttonLabel}
      </Button>
    </div>
  );
};

export default ProgramUnavailable;
