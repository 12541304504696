import { getPreviewText } from 'athena/helpers/utils';
import { Cadence } from 'redux/schemas/models/mentoring-program-sessions';
import { getISOString } from './session-edit-mode';

type SessionPreviewTextProps = {
  cadence: Cadence;
  scheduledDate: string | moment.Moment;
  endsOn: string | moment.Moment;
  closureDate: string | moment.Moment;
};

const SessionPreviewText = (props: SessionPreviewTextProps) => {
  const { cadence, scheduledDate, endsOn, closureDate } = props;
  const values = {
    cadence,
    startDate: getISOString(scheduledDate),
    endDate: getISOString(endsOn),
    closureDate: getISOString(closureDate),
  };
  if (!values.cadence || !values.startDate || (!values.endDate && !values.closureDate)) {
    return null;
  }
  return (
    <div className='mt-4'>{getPreviewText(values)}</div>
  );
};

export default SessionPreviewText;
