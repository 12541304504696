import { Button } from 'react-bootstrap';
import { useEffect, useRef, useState } from 'react';
import { Cadence } from 'redux/schemas/models/mentoring-program-sessions';
import { getDayOfWeekAndNthWeek } from 'athena/helpers/utils';
import NvIcon from 'shared/components/nv-icon';
import t from 'react-translate';
import { useFormContext } from 'react-hook-form';
import { css } from '@emotion/react';
import { black, gray5, primary } from 'styles/global_defaults/colors';
import { quarterSpacing } from 'styles/global_defaults/scaffolding';
import { getISOString } from './session-edit-mode';
import MeetingPlanDetails from './meeting-plan-details';

const styles = css`
  border: 1px solid ${gray5};
  border-radius: ${quarterSpacing}px;
  .toggle-button {
    color: ${black};
    .icon {
      color: ${primary};
    }
    &:hover {
      text-decoration: none;
      .icon {
        text-decoration: unset;
      }
      .recurrence-text {
        text-decoration: underline;
        color: ${primary};
      }
    }
    &:focus, &:active {
      box-shadow: none !important;
      text-decoration: none;
    }
  }
`;

const RecurrenceToggle = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [toggleText, setToggleText] = useState('');
  const contentRef = useRef(null);

  const translationsBase = t.MENTORING_PROGRAMS.PARTICIPANT_HOME.PLAN_SESSION_FLYOUT.EDIT_MODE;
  const translations = translationsBase.RECURRENCE_TOGGLE;

  const methods = useFormContext();
  const { watch } = methods;
  const scheduledDateFormValue = watch('scheduledDate');
  const cadenceFormValue = watch('cadence');

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (isOpen) {
      contentRef.current?.focus();
    }
  }, [isOpen]);

  useEffect(() => {
    if (!cadenceFormValue) {
      return;
    }
    const { dayOfWeek, nthWeek } = getDayOfWeekAndNthWeek(
      getISOString(scheduledDateFormValue),
    );
    switch (cadenceFormValue) {
      case Cadence.WEEKLY:
        setToggleText(translations.WEEKLY(dayOfWeek));
        break;
      case Cadence.BI_WEEKLY:
        setToggleText(translations.BI_WEEKLY(dayOfWeek));
        break;
      case Cadence.MONTHLY:
        setToggleText(translations.MONTHLY(
          dayOfWeek,
          translationsBase.PREVIEW_TEXT.NTH_WEEK[nthWeek](),
        ));
        break;
      default:
        setToggleText(translations.WEEKLY(dayOfWeek));
    }
  }, [scheduledDateFormValue, cadenceFormValue]);

  return (
    <div css={styles} className='d-flex align-items-start flex-column p-2 mb-6'>
      <Button
        variant='link'
        onClick={handleToggle}
        className='d-flex align-items-center p-0 toggle-button'
        aria-expanded={isOpen}
        aria-controls='recurrence-content'
        aria-label={toggleText}
      >
        <NvIcon icon='recurring' size='xss-smallest' className='mr-2' />
        <div className='recurrence-text'>{toggleText}</div>
      </Button>
      <section
        id='recurrence-content'
        ref={contentRef}
        className='w-100 pt-3'
        hidden={!isOpen}
        tabIndex={-1}
        aria-hidden={!isOpen}
      >
        <MeetingPlanDetails />
      </section>
    </div>
  );
};

export default RecurrenceToggle;
