import { Button } from 'react-bootstrap';
import t from 'react-translate';
import { config } from '@config/pendo.config.json';

export enum SessionButtonTypes {
  CANCEL = 'CANCEL',
  SAVE = 'SAVE',
  ADD = 'ADD',
  CREATE_SESSIONS = 'CREATE_SESSIONS',
  UPDATE_ALL_REMAINING_SESSIONS = 'UPDATE_ALL_REMAINING_SESSIONS',
}

const dataQATag: Record<SessionButtonTypes, string> = {
  [SessionButtonTypes.CANCEL]: 'cancel',
  [SessionButtonTypes.SAVE]: 'saveSession',
  [SessionButtonTypes.ADD]: 'addSession',
  [SessionButtonTypes.CREATE_SESSIONS]: 'createSessions',
  [SessionButtonTypes.UPDATE_ALL_REMAINING_SESSIONS]: 'updateAllRemainingSessions',
};

type SessionScheduleButtonsProps = {
  type?: SessionButtonTypes;
  disabled: boolean;
  onCancel: () => void;
};

type SessionButtonProps = {
  buttonType: SessionButtonTypes;
  disabled: boolean;
  onClick?: () => void;
  translations: any;
  pendo: any;
};

const SessionButton = ({
  buttonType,
  disabled,
  onClick,
  translations,
  pendo,
}: SessionButtonProps) => {
  const isCancel = buttonType === SessionButtonTypes.CANCEL;
  return (
    <Button
      size='lg'
      onClick={onClick}
      disabled={isCancel ? false : disabled}
      variant={isCancel ? 'secondary' : 'primary'}
      data-qa={pendo[dataQATag[buttonType]]}
      type={isCancel ? 'button' : 'submit'}
    >
      {translations[buttonType]()}
    </Button>
  );
};

const SessionScheduleButtons = ({
  type = SessionButtonTypes.SAVE,
  disabled,
  onCancel,
}: SessionScheduleButtonsProps) => {
  const translations = t.MENTORING_PROGRAMS.PARTICIPANT_HOME.PLAN_SESSION_FLYOUT.EDIT_MODE;
  const pendo = config.pendo.athena.mentorshipProgram.participantHome.sessions.planSessionFlyout;

  return (
    <div className='button-row'>
      <SessionButton
        buttonType={SessionButtonTypes.CANCEL}
        onClick={onCancel}
        disabled={disabled}
        translations={translations}
        pendo={pendo}
      />
      <SessionButton
        buttonType={type}
        disabled={disabled}
        translations={translations}
        pendo={pendo}
      />
    </div>
  );
};

export default SessionScheduleButtons;
