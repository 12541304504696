import React from 'react';
import moment from 'moment-timezone';
import NvIcon from 'shared/components/nv-icon';
import { MentoringProgramContextValue } from 'athena/components/mentoring-program/context';
import { MentorshipProgramSession } from 'redux/schemas/models/mentoring-program-sessions';

const formatSessionTime = (dateString, duration, timeZone) => {
  const { ianaTimeZone, timeZoneAbbreviation } = timeZone;
  try {
    const startTime = moment.tz(dateString, ianaTimeZone);
    const endTime = duration ? startTime.clone().add(duration, 'minutes') : null;
    const formattedStartTime = startTime.format('ddd, MMMM D • h:mm A');
    const formattedEndTime = endTime ? ` - ${endTime.format('h:mm A')}` : '';
    return `${formattedStartTime}${formattedEndTime} ${timeZoneAbbreviation}`;
  } catch (error) {
    return '';
  }
};

type SessionTimeViewModeProps = {
  date: MentorshipProgramSession['scheduledDate'];
  timeZone: MentoringProgramContextValue['timeZone'];
  duration: MentorshipProgramSession['duration'];
  isRecurring?: boolean;
};

const SessionTimeViewMode = React.memo(({
  date,
  timeZone,
  duration,
  isRecurring = false,
}: SessionTimeViewModeProps) => (
  <div className='d-flex pb-1 align-items-center regular'>
    {formatSessionTime(date, duration, timeZone)}
    {isRecurring && <NvIcon icon='recurring' size='smallest' className='pl-2 recurring-icon' />}
  </div>
));

export default SessionTimeViewMode;
