import MentoringProgramContext from 'athena/components/mentoring-program/context';
import moment from 'moment';
import { useMemo, useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import t from 'react-translate';
import { Cadence } from 'redux/schemas/models/mentoring-program-sessions';
import NvDatePicker, { DatePickerType } from 'shared/components/inputs/nv-datepicker';
import NvDropdown, { NvDropdownOption, NvDropdownButtonStyle } from 'shared/components/inputs/nv-dropdown';
import { values } from 'underscore';

const getMomentDay = (date: string) => (
  date ? moment(date).startOf('day') : moment().startOf('day')
);

const getMin = (date: string) => (
  date ? moment.max(moment(date), moment().startOf('day')) : moment().startOf('day')
);

type MeetingPlanDetailsType = {
  isOptional?: boolean;
};

const MeetingPlanDetails = (props: MeetingPlanDetailsType) => {
  const { isOptional } = props;
  const { setValue } = useFormContext();
  const translations = t.MENTORING_PROGRAMS.PARTICIPANT_HOME.PLAN_SESSION_FLYOUT.EDIT_MODE;
  const { mentoringProgram } = useContext(MentoringProgramContext);
  const { endDate: closureDate } = mentoringProgram || {};
  const { getValues } = useFormContext();
  const { scheduledDate } = getValues();

  const cadenceItems = useMemo(() => {
    const cadences = Object.values(Cadence);
    const items = cadences.map((cadence) => ({
      id: cadence,
      type: 'text',
      text: cadence ? translations.CADENCE_OPTIONS[cadence.toLocaleUpperCase()]() : '',
      callback: () => setValue('cadence', cadence, { shouldDirty: true }),
    })) as NvDropdownOption[];
    // Remove last item (Default) from cadence options because it's empty
    items.pop();
    return items;
  }, [setValue, translations]);

  return (
    <>
      {isOptional && (
        <>
          <div className='d-flex align-items-center heading-4'>
            {translations.OPTIONAL_MEETING_PLAN.TITLE()}
          </div>
          <div className='text-large-body pb-4'>
            {translations.OPTIONAL_MEETING_PLAN.DESCRIPTION()}
          </div>
        </>
      )}
      <div className='d-flex align-items-center cadence'>
        <div className='session-cadence pr-2'>
          <div className='session-time mb-1'>{translations.CADENCE()}</div>
          <NvDropdown
            initialIndex={values(Cadence).indexOf(getValues('cadence'))}
            showSelectedIndicator
            items={cadenceItems}
            menuClassName='w-100'
            buttonStyle={NvDropdownButtonStyle.FORM_SMALL}
          />
        </div>
        <div className='session-ends-on'>
          <div className='session-time mb-1'>{translations.ENDS_ON()}</div>
          <NvDatePicker
            withForm
            name='endsOn'
            type={DatePickerType.DATETIME}
            highlightDates={[getMomentDay(scheduledDate)]}
            min={getMin(scheduledDate)}
            max={closureDate ? moment(closureDate) : undefined}
            placeholder={translations.ENDS_ON_PLACEHOLDER()}
          />
        </div>
      </div>
    </>
  );
};

export default MeetingPlanDetails;
