import { BaseUser, User } from 'redux/schemas/models/my-account';

// TODO: Update enum values when backend corrects the value
export enum MentorshipStatus {
  MATCHED = 'matched',
  UNMATCHED = 'unmatched',
  MATCHED_WITH_AVAILABILITY = 'matched with availability',
}

export enum MentoringProgramRole {
  MENTORSHIP_PROGRAM_MENTOR = 'MENTORSHIP_PROGRAM_MENTOR',
  MENTORSHIP_PROGRAM_ADMIN = 'MENTORSHIP_PROGRAM_ADMIN',
  MENTORSHIP_PROGRAM_MENTEE = 'MENTORSHIP_PROGRAM_MENTEE',
}

export const AdminBitValue = 512;
export const MentorBitValue = 256;
export const MenteeBitValue = 1024;

type MentoringProgramPermissionBitValue =
  | typeof AdminBitValue
  | typeof MentorBitValue
  | typeof MenteeBitValue;

export enum CompletionStatus {
  NOT_STARTED = 'not_started',
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
}

export enum StepKey {
  PROFILE_COMPLETION = 'profileCompletion',
  PROFILE_REVIEW = 'profileReview',
  WAITING_CONNECTION = 'waitingConnection',
  WAITING_FIRST_SESSION = 'waitingFirstSession',
  CREATE_MEETING_PLAN = 'waitingPlanSession',
}

export type MentoringProgramEnrollmentRole = {
  name: MentoringProgramRole;
  permission: MentoringProgramPermissionBitValue;
};

export type StepInfo = {
  status: CompletionStatus;
};

export type States = {
  [key in StepKey]: StepInfo;
};

export type AssignedEnrollment = Pick<MentoringProgramEnrollment, 'id' | 'user' | 'role'>;

export type AssignedConnection = {
  isSessionCreated: boolean;
  connectionId: number;
  assignedEnrollment: AssignedEnrollment;
};

export type MentoringProgramEnrollment = {
  id: number;
  user: User;
  userId: number;
  mentorshipProgramId: number;
  mentorshipStatus: MentorshipStatus;
  role: MentoringProgramEnrollmentRole;
  assignedConnections: AssignedConnection[];
  states: States;
  firstConnectionAnnounced: boolean;
};

export enum MentoringProgramEnrollmentError {
  PROGRAM_ADMIN_CANNOT_CHANGE_ROLE_ERROR = 'program_admin_cannot_change_role',
}

export type MentoringProgramEnrollmentResult = {
  newlyEnrolled: BaseUser[];
  existing: BaseUser[];
  errors?: {
    error: MentoringProgramEnrollmentError;
  }[]
};
