import React from 'react';
import NvIcon from 'shared/components/nv-icon';

type LocationViewModeProps = {
  location: String;
};

const LocationViewMode = React.memo(({ location }: LocationViewModeProps) => (
  <div className='d-flex location pb-4 align-items-center regular'>
    <NvIcon icon='location' size='smallest' className='pr-2' />
    <div className='location-text'>{location}</div>
  </div>
));

export default LocationViewMode;
