import React from 'react';
import t from 'react-translate';
import { IntegrationType } from 'redux/schemas/models/mentoring-program-sessions';

import { css } from '@emotion/react';
import { msTeamsColor, zoomColor } from 'athena/styles/colors';

import { Button } from 'react-bootstrap';
import NvIcon from 'shared/components/nv-icon';

const integrationSettings = {
  [IntegrationType.MS_TEAMS]: {
    color: msTeamsColor,
    label: 'JOIN_MS_TEAMS_MEETING',
  },
  [IntegrationType.ZOOM]: {
    color: zoomColor,
    label: 'JOIN_ZOOM_MEETING',
  },
};

type MeetingLinkViewModeProps = {
  link?: string;
  integrationType?: IntegrationType;
};

const MeetingLinkViewMode = React.memo(({ link, integrationType }: MeetingLinkViewModeProps) => {
  const { color, label } = integrationSettings[integrationType];
  const styles = css`
    .meeting-link-text {
      color: ${color};
    }
  `;

  return (
    <div css={styles} className='d-flex meeting-link pb-1 align-items-center'>
      <NvIcon
        icon='zoom'
        size='smallest'
        className='pr-2'
      />
      <Button
        href={link}
        target='_blank'
        variant='link'
        className='heading-5 meeting-link-text p-0'
      >
        {t.MENTORING_PROGRAMS.PARTICIPANT_HOME.PLAN_SESSION_FLYOUT.MEETING_LINK[label]()}
      </Button>
    </div>
  );
});

export default MeetingLinkViewMode;
