import {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import _ from 'lodash';
import moment from 'moment';
import t from 'react-translate';

// Redux
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';
import { fetchMeetingPlan } from 'redux/actions/mentoring-program-sessions';
import { MentorshipProgramSession } from 'redux/schemas/models/mentoring-program-sessions';
import { getMentorshipProgramSessionPlan } from 'redux/selectors/mentorship-program-sessions';

// Styles
import { css } from '@emotion/react';
import { gray1, gray2, gray5, gray6 } from 'styles/global_defaults/colors';
import {
  semiBoldFontWeight,
  textLargeFontSize,
  textMediumLineHeight,
  textMediumFontSize,
  textSmallLineHeight,
  headerLineHeight,
} from 'styles/global_defaults/fonts';
import {
  halfSpacing,
  largeSpacing,
  quarterSpacing,
  standardSpacing,
  threeQuartersSpacing,
} from 'styles/global_defaults/scaffolding';

// Components
import MentoringProgramContext from 'athena/components/mentoring-program/context';
import NvFlyoutModal, { ModalType } from 'shared/components/nv-flyout-modal';
import SessionEditMode from './session-edit-mode/session-edit-mode';
import SessionViewMode from './session-view-mode/session-view-mode';

import useConnection from 'athena/hooks/use-connection';

export const DEFAULT_DURATION = 30;

const styles = css`
  height: 100%;
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    justify-content: space-between;
    padding: ${standardSpacing}px ${largeSpacing}px;
    font-weight: ${semiBoldFontWeight};
    font-size: ${textLargeFontSize}px;
    line-height: ${textMediumLineHeight}px;
    border-bottom: 1px solid ${gray5};
  }

  .content {
    flex: 1;
    padding: ${halfSpacing}px ${largeSpacing}px;

    .session-title, .session-time, .session-action-items, .session-details {
      color: ${gray2};
      font-weight: ${semiBoldFontWeight};
      font-size: ${textMediumFontSize}px;
      line-height: ${textSmallLineHeight}px;
    }

    .title {
      margin-bottom: ${threeQuartersSpacing}px;
    }

    .date-time {
      & > div, .react-datepicker-wrapper {
        width: 100%;
      }

      width: 100%;
      padding-bottom: ${largeSpacing}px;
    }

    .session-action-items {
      border-top: 1px solid ${gray5};
      padding-top: ${threeQuartersSpacing}px;
    }

    .session-details {
      padding-top: ${standardSpacing}px;
      border-top: 1px solid ${gray5};
    }

    .session-details-description {
      color: ${gray1};
      line-height: ${headerLineHeight}px;
      padding-top: ${quarterSpacing}px;
      padding-bottom: ${halfSpacing}px;
    }
  }

  form {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .button-row {
    padding: ${largeSpacing}px;
    text-align: right;
    border-top: 1px solid ${gray6};

    button {
      margin-left: ${threeQuartersSpacing}px;
    }
  }
`;

const FLYOUT_MODAL_WIDTH = 640;

const getInitialSessionDraft = (session, defaultTitle) => ({
  title: session?.title ?? defaultTitle ?? '',
  scheduledDate: session?.scheduledDate ? moment(session.scheduledDate) : null,
  details: session?.details ?? '',
  ...session,
});

type PlanSessionFlyoutModalProps = {
  onClose: () => void;
  session?: MentorshipProgramSession;
  editMode?: boolean;
  createMeetingPlan?: boolean;
};

const PlanSessionFlyoutModal = ({
  onClose,
  session,
  editMode = false,
  createMeetingPlan = false,
}: PlanSessionFlyoutModalProps) => {
  const { mentoringProgram } = useContext(MentoringProgramContext);
  const { id: programId, mentorshipProgramEnrollment: enrollmentId } = mentoringProgram;
  const dispatch = useAppDispatch();
  const translationBase = t.MENTORING_PROGRAMS.PARTICIPANT_HOME.PLAN_SESSION_FLYOUT;
  const [isFormDirty, setIsFormDirty] = useState(false);

  const { connection, mentorshipProgramEnrollment } = useConnection(enrollmentId);
  const { user: connectionUser } = connection?.assignedEnrollment || {};
  const { user: currentUser } = mentorshipProgramEnrollment || {};

  const meetingPlan = useSelector(
    getMentorshipProgramSessionPlan(connection?.connectionId),
  );

  const defaultTitle = translationBase.DEFAULT_TITLE(currentUser.firstName, connectionUser.firstName);
  const [sessionDraft, setSessionDraft] = useState<MentorshipProgramSession>(
    getInitialSessionDraft(session, defaultTitle)
  );
  const isEdit = !!session?.id || !!sessionDraft?.id;
  const [viewMode, setViewMode] = useState<boolean>(!editMode && isEdit);

  const handleClose = useCallback((shouldCloseModal = true) => {
    const dialogOptions = {
      title: translationBase.CONFIRMATION_MODAL.HEADER(),
      bodyText: translationBase.CONFIRMATION_MODAL.BODY(),
      confirmText: t.FORM.DISCARD(),
    };

    if (isFormDirty) {
      dispatch(
        openConfirmationDialog({
          ...dialogOptions,
          onConfirm: () => {
            if (shouldCloseModal || !isEdit) onClose();
            else {
              setIsFormDirty(false);
              setViewMode(true);
            }
          },
          onCancel: () => {
            if (isEdit && shouldCloseModal) {
              setIsFormDirty(false);
              setViewMode(true);
            }
          },
        })
      );
      return;
    }

    if (isEdit) {
      if (shouldCloseModal || viewMode) onClose();
      else setViewMode(true);
      return;
    }

    onClose();
  }, [isFormDirty, isEdit, viewMode, onClose]);

  useEffect(() => {
    if (session?.mentorshipProgramSessionPlanId && !meetingPlan) {
      dispatch(fetchMeetingPlan({
        programId,
        mentorshipProgramSessionPlanId: session.mentorshipProgramSessionPlanId,
        mentorshipProgramConnectionId: connection?.connectionId,
      }));
    }
  }, [session?.mentorshipProgramSessionPlanId]);

  return (
    <Fragment>
      <NvFlyoutModal
        type={ModalType.RIGHT}
        width={FLYOUT_MODAL_WIDTH}
        onClose={handleClose}
      >
        <div css={styles}>
          {viewMode ? (
            <SessionViewMode
              session={sessionDraft}
              connection={connection}
              setViewMode={setViewMode}
              onClose={handleClose}
            />
          ) : (
            <SessionEditMode
              session={sessionDraft}
              isEdit={isEdit}
              createMeetingPlan={createMeetingPlan}
              meetingPlan={meetingPlan}
              onClose={handleClose}
              onSessionCreated={() => {
                setIsFormDirty(false);
                if (isEdit || createMeetingPlan) onClose();
                else setViewMode(true);
              }}
              onSessionUpdated={() => {
                setIsFormDirty(false);
                setViewMode(true);
              }}
              onSubmit={(data) => setSessionDraft(data)}
              onFormDirtyChange={setIsFormDirty}
            />
          )}
        </div>
      </NvFlyoutModal>
    </Fragment>
  );
};

export default PlanSessionFlyoutModal;
