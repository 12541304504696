import moment from 'moment';
import t from 'react-translate';
import { useContext, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { AngularContext } from 'react-app';
import { DATE_FORMAT, PanelStateKey } from 'athena/components/constants';
import CollapsiblePanel from 'athena/components/collapsible-panel';
import MentoringProgramContext from 'athena/components/mentoring-program/context';
import {
  CompletionStatus,
  MentoringProgramEnrollment,
  States,
  StepKey,
} from 'redux/schemas/models/mentoring-program-enrollments';
import { MentorshipProgramSession } from 'redux/schemas/models/mentoring-program-sessions';
import { getMentoringProgramEnrollment } from 'redux/selectors/mentoring-program-enrollments';
import StepItem from './step-item';
import { Step } from './types';
import CompleteProfileButton from './profile-completion/complete-profile-button';
import { config } from '@config/pendo.config.json';
import SlideTransition, { Slide } from 'athena/components/slide-transition';
import StarsConfetti from 'athena/components/stars-confetti';

const DAYS_BEFORE_MATCH = 5;

const isWaitingFirstSessionCompleted = (states: States) => (
  states[StepKey.WAITING_FIRST_SESSION]?.status === CompletionStatus.COMPLETED
);

const hasMeetingPlan = (states: States) => (
  states[StepKey.CREATE_MEETING_PLAN]?.status === CompletionStatus.COMPLETED
);

const getStatus = (states: States, key: string, isSessionCreated: boolean) => {
  let status = states[key]?.status || CompletionStatus.NOT_STARTED;
  if (key === StepKey.PROFILE_COMPLETION) {
    // Set status to NOT_STARTED if the profile review is not started and the profile completion is completed
    if (
      states[StepKey.PROFILE_REVIEW]?.status === CompletionStatus.NOT_STARTED
    ) {
      status = CompletionStatus.NOT_STARTED;
    }
  }
  if (key === StepKey.WAITING_FIRST_SESSION && !isSessionCreated) {
    status = CompletionStatus.NOT_STARTED;
  }
  return status;
};

const getProfileCompletionTitle = (states) => {
  // Show review your profile title if profile_completion is completed
  const title =
    states[StepKey.PROFILE_COMPLETION]?.status === CompletionStatus.COMPLETED
      ? t.MENTORING_PROGRAMS.PARTICIPANT_HOME.STEPS.LIST.REVIEW_YOUR_PROFILE.TITLE()
      : t.MENTORING_PROGRAMS.PARTICIPANT_HOME.STEPS.LIST.COMPLETE_YOUR_PROFILE.TITLE();
  return title;
};

type StepListProps = {
  showPlanSessionFlyout: (
    session?: MentorshipProgramSession,
    editMode?: boolean,
    createMeetingPlan?: boolean,
  ) => void,
};

const SLIDE_OUT_DELAY = 5000;

const StepList = ({ showPlanSessionFlyout }: StepListProps) => {
  const { mentoringProgram } = useContext(MentoringProgramContext);
  const { matchAnnouncementDate, mentorshipProgramEnrollment: enrollmentId } =
    mentoringProgram || {};
  const mentorshipProgramEnrollment: MentoringProgramEnrollment = useSelector(
    (state) => getMentoringProgramEnrollment(state, enrollmentId)
  );

  const { injectServices } = useContext(AngularContext);
  const [$state] = injectServices(['$state']);
  const connectionId = parseInt($state.params.connectionId, 10);

  const { states, assignedConnections } = mentorshipProgramEnrollment || {};

  const connection =
    assignedConnections?.find(
      (connec) => connec.connectionId === connectionId
    ) ?? assignedConnections?.[0];
  const { isSessionCreated } = connection || {};

  const completionDate = matchAnnouncementDate
    ? moment(matchAnnouncementDate)
        .subtract(DAYS_BEFORE_MATCH, 'days')
        .format(DATE_FORMAT)
    : null;

  const formattedMatchAnnouncementDate = matchAnnouncementDate
    ? moment(matchAnnouncementDate).format(DATE_FORMAT)
    : null;
  const isMatchUpcoming = moment(matchAnnouncementDate).isAfter(moment());
  const isMatchPast = moment().isAfter(moment(matchAnnouncementDate));
  const upcomingMatchMessage =
    t.MENTORING_PROGRAMS.PARTICIPANT_HOME.STEPS.LIST.WAIT_TO_BE_CONNECTED.UPCOMING_MATCH_DATE(
      formattedMatchAnnouncementDate
    );
  const passedMatchMessage = assignedConnections?.length
    ? t.MENTORING_PROGRAMS.PARTICIPANT_HOME.STEPS.LIST.WAIT_TO_BE_CONNECTED.PASSED_MATCH_DATE_WITH_CONNECTION(
        formattedMatchAnnouncementDate
      )
    : t.MENTORING_PROGRAMS.PARTICIPANT_HOME.STEPS.LIST.WAIT_TO_BE_CONNECTED.PASSED_MATCH_DATE_WITHOUT_CONNECTION();
  const getContentBasedOnMatchAnnouncementDate = () =>
    isMatchUpcoming ? upcomingMatchMessage : passedMatchMessage;

  const buttonLabel = t.MENTORING_PROGRAMS.PARTICIPANT_HOME.STEPS.LIST.PLAN_FIRST_SESSION.ADD_SESSION();

  const stepsList: { [key in StepKey]?: Step } = {
    [StepKey.PROFILE_COMPLETION]: {
      title: getProfileCompletionTitle(states),
      content: completionDate
        ? t.MENTORING_PROGRAMS.PARTICIPANT_HOME.STEPS.LIST.COMPLETE_YOUR_PROFILE.CONTENT(
            completionDate
          )
        : null,
      showBadge: true,
      ctaButton: <CompleteProfileButton states={states} />,
    },
    [StepKey.WAITING_CONNECTION]: {
      title:
        t.MENTORING_PROGRAMS.PARTICIPANT_HOME.STEPS.LIST.WAIT_TO_BE_CONNECTED.TITLE(),
      content: matchAnnouncementDate
        ? getContentBasedOnMatchAnnouncementDate()
        : null,
    },
    [StepKey.WAITING_FIRST_SESSION]: {
      title:
        t.MENTORING_PROGRAMS.PARTICIPANT_HOME.STEPS.LIST.PLAN_FIRST_SESSION.TITLE(),
      content:
        t.MENTORING_PROGRAMS.PARTICIPANT_HOME.STEPS.LIST.PLAN_FIRST_SESSION.CONTENT(),
      ctaButton:
        isMatchPast && assignedConnections.length && !isWaitingFirstSessionCompleted(states) ? (
          <Button
            size="lg"
            onClick={() => showPlanSessionFlyout(null, true)}
            data-qa={
              config.pendo.athena.mentorshipProgram.participantHome.steps
                .addFirstSession
            }
          >
            {buttonLabel}
          </Button>
        ) : null,
    },
    [StepKey.CREATE_MEETING_PLAN]: {
      title: t.MENTORING_PROGRAMS.PARTICIPANT_HOME.STEPS.LIST.CREATE_MEETING_PLAN.TITLE(),
      content: t.MENTORING_PROGRAMS.PARTICIPANT_HOME.STEPS.LIST.CREATE_MEETING_PLAN.CONTENT(),
      ctaButton: isWaitingFirstSessionCompleted(states) && !hasMeetingPlan(states) ? (
        <Button
          size='lg'
          onClick={() => showPlanSessionFlyout(null, true, true)}
          data-qa={config.pendo.athena.mentorshipProgram.participantHome.steps.createMeetingPlan}
        >
          {t.MENTORING_PROGRAMS.PARTICIPANT_HOME.STEPS.LIST.CREATE_MEETING_PLAN.CREATE_PLAN()}
        </Button>
      ) : null,
    },
  };

  const areAllStepsCompleted = Object.keys(stepsList).every(
    (key) =>
      getStatus(states, key, isSessionCreated) === CompletionStatus.COMPLETED
  ) || hasMeetingPlan(states);
  const [showConfetti, setShowConfetti] = useState(false);

  const onSeen = () => {
    if (areAllStepsCompleted) {
      setShowConfetti(true);
      setTimeout(() => {
        setShowConfetti(false);
      }, 3000);
    }
  };

  const panelTitle = t.MENTORING_PROGRAMS.PARTICIPANT_HOME.STEPS.TITLE();

  return (
    <SlideTransition
      type={Slide.OUT}
      showTransition={areAllStepsCompleted}
      delay={SLIDE_OUT_DELAY}
      onSeen={onSeen}
    >
      <CollapsiblePanel
        title={panelTitle}
        panelState={{
          persistState: true,
          localStorageKey: PanelStateKey.STEPS_LIST_STATE,
        }}
        dataQa={
          config.pendo.athena.mentorshipProgram.participantHome.steps.list
        }
        ariaLabel={panelTitle}
        className={showConfetti ? 'show-gradient' : ''}
      >
        <div className="mt-4">
          {Object.entries(stepsList).map(
            ([key, { title, content, showBadge, ctaButton }], index) => {
              let component = (
                <StepItem
                  key={key}
                  status={getStatus(states, key, isSessionCreated)}
                  title={title}
                  content={content}
                  showBadge={showBadge}
                  ctaButton={ctaButton}
                />
              );
              if (index === Object.entries(stepsList).length - 1)
                return (
                  <StarsConfetti key={key} showLeft={showConfetti}>
                    {component}
                  </StarsConfetti>
                );
              return component;
            }
          )}
        </div>
      </CollapsiblePanel>
    </SlideTransition>
  );
};

export default StepList;
