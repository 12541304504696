import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentUser } from 'redux/selectors/users';

const DEFAULT_TIME_ZONE_ABBREVIATION = 'PST';

type TimeZoneData = {
  ianaTimeZone: string;
  timeZoneAbbreviation: string;
};

const getTimeZoneAbbreviation = (tz, lang) => {
  const ianaTimeZone = tz ?? Intl.DateTimeFormat().resolvedOptions().timeZone;
  const language = lang
    ? lang.replace('_', '-')
    : Intl.DateTimeFormat().resolvedOptions().locale;
  try {
    const date = new Date();
    const options: Intl.DateTimeFormatOptions = { timeZone: ianaTimeZone, timeZoneName: 'short' };
    const timeString = date.toLocaleString(language, options);

    const abbreviation = timeString.match(/[A-Z]+$/)[0];
    return abbreviation;
  } catch (error) {
    return DEFAULT_TIME_ZONE_ABBREVIATION;
  }
};

const getIanaTimeZone = ({ timeZone, browserTimeZone }) => (
  timeZone || browserTimeZone || Intl.DateTimeFormat().resolvedOptions().timeZone
);

const useTimeZone = () => {
  const user = useSelector(getCurrentUser);
  return useMemo<TimeZoneData>(() => {
    const ianaTimeZone = getIanaTimeZone(user ?? {});
    return {
      ianaTimeZone,
      timeZoneAbbreviation: getTimeZoneAbbreviation(ianaTimeZone, user?.platformLanguage),
    };
  }, [user]);
};

export default useTimeZone;
