import { useContext, useEffect, useState } from 'react';
import t from 'react-translate';
import moment from 'moment';

// Redux
import { useAppDispatch } from 'redux/store';
import { CompletionStatus, StepKey } from 'redux/schemas/models/mentoring-program-enrollments';
import {
  Cadence,
  MentorshipProgramSession,
  MentorshipProgramMeetingPlan,
} from 'redux/schemas/models/mentoring-program-sessions';
import {
  createMentoringProgramSession,
  updateMentoringProgramSession,
  resetMentoringProgramSessionsList,
} from 'redux/actions/mentoring-program-sessions';

// Styles
import { css } from '@emotion/react';
import { gray6, white } from 'styles/global_defaults/colors';
import { quarterSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';

// Form
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { getSanitizedStyles } from 'shared/utils';

// Components
import SessionScheduleButtons, { SessionButtonTypes } from './session-schedule-buttons';
import SessionScheduleLocation from './session-schedule-location';
import SessionScheduleRecurrence from './session-schedule-recurrence';
import MentoringProgramContext from 'athena/components/mentoring-program/context';
import SessionPreviewText from './session-preview-text';
import SessionUpdatePreviewText from './session-update-preview-text';

import useConnection from 'athena/hooks/use-connection';
import { DEFAULT_DURATION } from '../plan-session-flyout-modal';
import RecurrenceToggle from './recurrence-toggle';
import ApplyChangesModal, { ApplyChangesOption } from './apply-changes-modal';
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';
import getCadenceWarningModal from './utils';
import _ from 'lodash';

const styles = css`
  .bs4-dropdown {
    .title {
      margin-bottom: 0;
    }
  }

  .session-date-time {
    .session-next-meeting {
      flex: 3;
      .react-datepicker-wrapper {
        width: 100%;
      }
    }
    .session-timezone {
      flex: 1;
    }
    .session-duration {
      flex: 1;
      .bs4-dropdown-menu {
        max-height: 210px;
        overflow-y: scroll;
      }
    }
  }

  .cadence {
    .session-cadence {
      flex: 1;
    }
    .session-ends-on {
      flex: 1;
      .react-datepicker-wrapper {
        width: 100%;
      }
    }
  }
  .bs4-input-group > input {
    background-color: ${white};
    border-radius: ${quarterSpacing}px;
  }
`;

export const getISOString = (date: string | moment.Moment) => {
  if (!date) return '';
  return moment(date).toISOString();
};

type ExtraProps = {
  closureDate: string;
  timezone: string;
  cadence: Cadence;
  endsOn: string | moment.Moment;
};

const getDefaultValues = (
  session: MentorshipProgramSession,
  extraProps: ExtraProps
) => {
  const {
    title,
    scheduledDate,
    duration,
    location = '',
    meetingLink,
    integrationType,
  } = session;
  const {
    closureDate,
    timezone,
    cadence,
    endsOn,
  } = extraProps;

  return {
    title,
    scheduledDate: getISOString(scheduledDate) || '',
    duration: duration || DEFAULT_DURATION,
    location,
    cadence: cadence || Cadence.DEFAULT,
    endsOn: getISOString(endsOn) || getISOString(closureDate) || '',
    meetingLink,
    integrationType,
    timezone,
    applyChangesOption: null,
  };
};

type SessionEditModeProps = {
  session: MentorshipProgramSession;
  meetingPlan?: MentorshipProgramMeetingPlan;
  isEdit?: boolean;
  createMeetingPlan?: boolean;
  onSessionCreated: () => void;
  onSessionUpdated: () => void;
  onClose: (shouldCloseModal?: boolean) => void;
  onSubmit: (data: any) => void;
  onFormDirtyChange?: (isDirty: boolean) => void;
};

const SessionEditMode = (props: SessionEditModeProps) => {
  const {
    session,
    meetingPlan,
    isEdit,
    createMeetingPlan,
    onSessionCreated,
    onSessionUpdated,
    onClose,
    onSubmit: onSessionSubmitted,
    onFormDirtyChange,
  } = props;

  const {
    duration,
    location,
    meetingLink,
    integrationType,
    mentorshipProgramSessionPlanId,
  } = session;

  const { cadence, endDate: endsOn } = meetingPlan || {};
  const hasMeetingPlan = !_.isEmpty(meetingPlan);
  const isPartOfTheMeetingPlan = !!mentorshipProgramSessionPlanId;
  const [submitButtonType, setSubmitButtonType] = useState<SessionButtonTypes>(SessionButtonTypes.SAVE);
  const [showMeetingPlan, setShowMeetingPlan] = useState<boolean>(createMeetingPlan);
  const [cadenceChanged, setCadenceChanged] = useState<boolean>(false);
  const [endDateChanged, setEndDateChanged] = useState<boolean>(false);
  const [shouldShowApplyChangesModal, setShouldShowApplyChangesModal] = useState<boolean>(false);
  const [shouldShowCadenceWarningModal, setShouldShowCadenceWarningModal] = useState<boolean>(false);
  const [shouldValidateBeforeSubmit, setShouldValidateBeforeSubmit] = useState<boolean>(false);
  const [shouldCallSubmit, setShouldCallSubmit] = useState(false);

  const cadenceWarningTranslations = t.MENTORING_PROGRAMS.PARTICIPANT_HOME.PLAN_SESSION_FLYOUT.EDIT_MODE.CADENCE_WARNING_MODAL;

  const { mentoringProgram, timeZone } = useContext(MentoringProgramContext);
  const { endDate: closureDate, mentorshipProgramEnrollment: enrollmentId } = mentoringProgram;

  const { connection, mentorshipProgramEnrollment } = useConnection(enrollmentId);
  const { states } = mentorshipProgramEnrollment;

  const customStyles = createMeetingPlan ? css`
    .location-info {
      border-bottom: 1px solid ${gray6};
      padding-bottom: ${standardSpacing}px;
    }
  ` : '';

  const validationSchema = yup.object().shape({
    title: yup.string().required(),
    scheduledDate: yup.string().required(),
    duration: yup.number(),
    location: yup.string().optional().nullable().max(190),
    cadence: yup.string().optional().nullable(),
    endsOn: yup.string().test('required', '', (value) => (
      (!isPartOfTheMeetingPlan && !showMeetingPlan) || !!value
    )),
    meetingLink: yup.string().url().optional().nullable(),
    integrationType: yup.string().optional().nullable(),
    timezone: yup.string().optional().nullable(),
    applyChangesOption: yup.string().optional().nullable(),
  });

  const shouldPickClosureDate = (createMeetingPlan && !isEdit) || (showMeetingPlan && _.isEmpty(meetingPlan));

  const extraProps = {
    closureDate: shouldPickClosureDate ? closureDate : null,
    timezone: timeZone.ianaTimeZone,
    cadence: (isPartOfTheMeetingPlan || createMeetingPlan) ? (cadence || Cadence.WEEKLY) : Cadence.DEFAULT,
    endsOn: (isPartOfTheMeetingPlan || showMeetingPlan) ? endsOn : '',
  };

  const defaultValues = getDefaultValues(session, extraProps);

  const methods = useForm({
    mode: 'all',
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const { handleSubmit, formState, setValue } = methods;
  const { isDirty } = formState;
  const cadenceFormValue = methods.watch('cadence');
  const scheduledDateFormValue = methods.watch('scheduledDate');
  const endsOnFormValue = methods.watch('endsOn');
  const isOptional = !hasMeetingPlan && !isEdit && !!scheduledDateFormValue && !createMeetingPlan;

  const dispatch = useAppDispatch();

  const formatSessionData = (data) => ({
    ...data,
    scheduledDate: moment(data.scheduledDate).toISOString(),
    programId: mentoringProgram.id,
    enrollmentId,
    mentorshipProgramConnectionId: connection.connectionId,
    cadence: createMeetingPlan ? (cadenceFormValue || Cadence.WEEKLY) : cadenceFormValue,
    createMeetingPlan: createMeetingPlan || (isOptional && !hasMeetingPlan && cadenceFormValue),
  });

  const shouldShowRecurrenceToggle = hasMeetingPlan && isPartOfTheMeetingPlan && isEdit;
  const shouldShowPreviewText = createMeetingPlan && !isEdit;
  const shouldShowUpdatePreviewText = (
    (isEdit && hasMeetingPlan && isPartOfTheMeetingPlan && endsOnFormValue)
    && (cadenceChanged || endDateChanged)
  );

  const handleSuccess = (action, callback) => {
    dispatch(resetMentoringProgramSessionsList({ resetList: true }));
    callback?.();
    onSessionSubmitted(action.payload?.[0]);
  };

  const onSubmit = (data) => {
    if (shouldValidateBeforeSubmit) {
      // We have 3 cases here:
      // 1. If the user hasn't changed the cadence, we should show the apply changes modal
      // 2. If the user has changed the cadence, we should show a warning modal
      // 3. If the user has changed the cadence or endsOn and one of the other fields,
      //    we should submit the form by setting the applyChangesOption inside data to ApplyChangesOption.REMAINING_SESSIONS
      if (!cadenceChanged) {
        setShouldShowApplyChangesModal(true);
        return;
      }
      const cadenceOrEndDateChanged = cadenceChanged || endDateChanged;
      const otherFieldsChanged = data.title !== session.title
        || getISOString(data.scheduledDate) !== getISOString(session.scheduledDate)
        || data.duration !== session.duration
        || data.location !== session.location
        || data.meetingLink !== session.meetingLink
        || data.integrationType !== session.integrationType;
      if (!cadenceOrEndDateChanged || !otherFieldsChanged) {
        setShouldShowCadenceWarningModal(true);
        return;
      }
    }

    const sessionData = formatSessionData(data);
    sessionData.endsOn = getISOString(sessionData.endsOn);

    if (isEdit) {
      dispatch(updateMentoringProgramSession({ ...sessionData, sessionId: session.id }))
        .then((action) => handleSuccess(action, onSessionUpdated));
    } else {
      dispatch(createMentoringProgramSession(sessionData))
        .then((action) => handleSuccess(action, onSessionCreated));
    }
  };

  useEffect(() => {
    if (isEdit) {
      setSubmitButtonType(cadenceChanged && isPartOfTheMeetingPlan
        ? SessionButtonTypes.UPDATE_ALL_REMAINING_SESSIONS
        : SessionButtonTypes.SAVE
      );
      return;
    }
    setSubmitButtonType(createMeetingPlan
      ? SessionButtonTypes.CREATE_SESSIONS
      : SessionButtonTypes.ADD
    );
  }, [cadenceChanged, createMeetingPlan, isEdit]);

  useEffect(() => {
    if (isOptional) {
      const hasFinishedAllSteps = states[StepKey.CREATE_MEETING_PLAN]?.status === CompletionStatus.COMPLETED;
      setShowMeetingPlan(hasFinishedAllSteps);
    }
  }, [isOptional]);

  useEffect(() => {
    onFormDirtyChange?.(isDirty);
  }, [isDirty]);

  useEffect(() => {
    setCadenceChanged(cadence !== cadenceFormValue);
  }, [cadenceFormValue]);

  useEffect(() => {
    if (getISOString(endsOn) !== getISOString(endsOnFormValue)) {
      setEndDateChanged(true);
    } else {
      setEndDateChanged(false);
    }
  }, [endsOnFormValue]);

  useEffect(() => {
    if (shouldShowCadenceWarningModal) {
      const sessions = meetingPlan?.mentorshipProgramSessions.filter(({ mentorshipProgramActionItems, details }) => (
        mentorshipProgramActionItems.length || details
      ));
      if (sessions.length === 0) {
        // We need to update the value of applyChangesOption to ApplyChangesOption.REMAINING_SESSIONS
        setValue('applyChangesOption', ApplyChangesOption.REMAINING_SESSIONS);
        setShouldValidateBeforeSubmit(false);
        setShouldCallSubmit(true);
        return;
      }
      const cadenceWarningModal = getCadenceWarningModal({
        translations: cadenceWarningTranslations,
        sessions,
        onCancel: () => setShouldShowCadenceWarningModal(false),
        onConfirm: () => {
          setShouldShowCadenceWarningModal(false);
          setShouldValidateBeforeSubmit(false);
          setShouldCallSubmit(true);
        }
      });
      dispatch(openConfirmationDialog(cadenceWarningModal));
    }
  }, [shouldShowCadenceWarningModal]);

  useEffect(() => {
    setShouldValidateBeforeSubmit(isEdit && hasMeetingPlan && isPartOfTheMeetingPlan);
  }, [isEdit, hasMeetingPlan, mentorshipProgramSessionPlanId]);

  useEffect(() => {
    if (shouldCallSubmit) {
      handleSubmit(onSubmit)();
      setShouldCallSubmit(false);
    }
  }, [shouldCallSubmit]);

  useEffect(() => {
    if (shouldPickClosureDate && !methods.getValues('endsOn')) {
      setValue('endsOn', getISOString(closureDate));
    }
  }, [shouldPickClosureDate]);

  const onApplyChanges = (option: ApplyChangesOption) => {
    setShouldShowApplyChangesModal(false);
    setShouldValidateBeforeSubmit(false);

    if (option === ApplyChangesOption.REMAINING_SESSIONS) {
      setShouldShowCadenceWarningModal(true);
      return;
    }

    setShouldCallSubmit(true);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='header'>
          <div>{t.MENTORING_PROGRAMS.PARTICIPANT_HOME.PLAN_SESSION_FLYOUT.HEADER()}</div>
        </div>
        <div className='content' css={getSanitizedStyles([styles, customStyles])}>
          {isEdit && (
            <div className='heading-4 pb-4 pt-4'>
              {t.MENTORING_PROGRAMS.PARTICIPANT_HOME.PLAN_SESSION_FLYOUT.EDIT_MODE.SUBTITLE()}
            </div>
          )}
          {!isEdit && createMeetingPlan && (
            <div className='text-large-body pb-4'>
              {t.MENTORING_PROGRAMS.PARTICIPANT_HOME.PLAN_SESSION_FLYOUT.EDIT_MODE.DESCRIPTION()}
            </div>
          )}
          <SessionScheduleLocation
            id={session?.id}
            isEdit={isEdit}
            meetingLink={meetingLink}
            integrationType={integrationType}
          />
          <SessionScheduleRecurrence
            duration={duration}
            isOptional={isOptional}
            showMeetingPlan={showMeetingPlan && _.isEmpty(meetingPlan)}
          />
          {shouldShowRecurrenceToggle && (
            <RecurrenceToggle />
          )}
          {shouldShowPreviewText && (
            <SessionPreviewText
              scheduledDate={scheduledDateFormValue}
              cadence={cadenceFormValue}
              endsOn={endsOnFormValue}
              closureDate={closureDate}
            />
          )}
          {shouldShowUpdatePreviewText && (
            <SessionUpdatePreviewText
              scheduledDate={scheduledDateFormValue}
              cadence={cadenceFormValue}
              endsOn={endsOnFormValue}
              closureDate={closureDate}
              cadenceChanged={cadenceChanged}
              endDateChanged={endDateChanged}
            />
          )}
        </div>
        <SessionScheduleButtons
          type={submitButtonType}
          onCancel={() => onClose(false)}
          disabled={formState.isSubmitting || !formState.isValid || !isDirty}
        />
      </form>
      <ApplyChangesModal
        show={shouldShowApplyChangesModal}
        onClose={() => setShouldShowApplyChangesModal(false)}
        onSave={onApplyChanges}
      />
    </FormProvider>
  );
};

export default SessionEditMode;
