import { useContext } from 'react';
import { AngularContext } from 'react-app';
import { getMentoringProgramEnrollment } from 'redux/selectors/mentoring-program-enrollments';
import { useSelector } from 'react-redux';
import { AssignedConnection, MentoringProgramEnrollment } from 'redux/schemas/models/mentoring-program-enrollments';

type UseConnectionType = {
  connection: AssignedConnection;
  connectionId: number;
  mentorshipProgramEnrollment: MentoringProgramEnrollment;
};

const useConnection = (enrollmentId, connectionId?) => {
  const { injectServices } = useContext(AngularContext);
  const [$state] = injectServices(['$state']);
  const connectionIdFromState = parseInt($state.params.connectionId, 10);
  const connectionIdToUse = connectionId || connectionIdFromState;
  const mentorshipProgramEnrollment = useSelector(state => getMentoringProgramEnrollment(state, enrollmentId));
  const { assignedConnections } = mentorshipProgramEnrollment || {};
  const connection = assignedConnections?.find(connec => connec.connectionId === connectionIdToUse) ?? assignedConnections?.[0];
  return {
    connection,
    connectionId: connectionIdToUse,
    mentorshipProgramEnrollment,
  } as UseConnectionType;
};

export default useConnection;
