import { useContext, useState } from 'react';
import t from 'react-translate';
import { getDefaultActionItemHandlers } from '../utils';
import { config } from '@config/pendo.config.json';

// Redux
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';
import {
  deleteMentoringProgramSession,
  resetMentoringProgramSessionsList,
  updateMentoringProgramSession,
} from 'redux/actions/mentoring-program-sessions';
import { MentorshipProgramSession } from 'redux/schemas/models/mentoring-program-sessions';
import { SaveStatus } from 'redux/schemas/app/mentoring-programs';
import {
  ActionItemOwner,
  MentoringProgramActionItem,
} from 'redux/schemas/models/mentoring-program-action-items';
import { removeDeletedActionItem } from 'redux/actions/mentoring-program-action-items';
import { getSessionActionItems } from 'redux/selectors/mentorship-program-sessions';

// Components
import MentoringProgramContext from 'athena/components/mentoring-program/context';
import NvAthenaFroala, { ResizeType } from 'athena/components/nv-athena-froala';
import SaveToast from 'athena/components/mentoring-program/program-configuration/save-toast';
import NvDropdown, {
  NvDropdownButtonStyle,
  NvDropdownOption,
} from 'shared/components/inputs/nv-dropdown';
import ActionItem from 'athena/components/action-item';
import ScheduleInfoCard from './schedule-info-card';

import { ToolbarOptions } from 'froala/helpers/nv-froala-constants';
import { DEFAULT_ACTION_ITEM } from 'athena/components/action-item/constants';

type SessionViewModeProps = {
  session: MentorshipProgramSession;
  connection: any;
  setViewMode: (isViewMode: boolean) => void;
  onClose: () => void;
};

const SessionViewMode = (props: SessionViewModeProps) => {
  const { session, connection, setViewMode, onClose } = props;
  const dataQATag = config.pendo.athena.mentorshipProgram.participantHome.sessions.planSessionFlyout;
  const dispatch = useAppDispatch();
  const connectionId = connection?.connectionId;
  const { mentoringProgram } = useContext(MentoringProgramContext);
  const { id: mentoringProgramId, mentorshipProgramEnrollment: enrollmentId } = mentoringProgram || {};
  const [sessionDetails, setSessionDetails] = useState(session.details);
  const mentoringProgramEnrollment = useSelector(state => (
    state.models.mentoringProgramEnrollments[enrollmentId]
  ));

  const [saveStatus, setSaveStatus] = useState<SaveStatus>();
  const [editingId, setEditingId] = useState<string | number>();
  const sessionActionItems: MentoringProgramActionItem[] = useSelector(getSessionActionItems(session?.id));

  const getDefaultActionItem = () => ({
    ...DEFAULT_ACTION_ITEM,
    assignedTo: mentoringProgramEnrollment,
    owner: { id: session?.id, type: ActionItemOwner.SESSION },
  });
  const connectionEnrollments = {
    [mentoringProgramEnrollment.id]: mentoringProgramEnrollment,
    [connection?.assignedEnrollment?.id]: connection?.assignedEnrollment,
  };

  const { onAdd, onUpdate, onDelete } = getDefaultActionItemHandlers(mentoringProgramId, dispatch)

  const deleteSession = () => {
    dispatch(openConfirmationDialog({
      title: t.MENTORING_PROGRAMS.PARTICIPANT_HOME.PLAN_SESSION_FLYOUT.OPTIONS.DELETE_THIS_SESSION(),
      bodyText: t.MENTORING_PROGRAMS.PARTICIPANT_HOME.PLAN_SESSION_FLYOUT.OPTIONS.DELETE_DESCRIPTION(),
      confirmText: t.FORM.DELETE(),
      onConfirm: () => {
        dispatch(deleteMentoringProgramSession({
          programId: mentoringProgram.id,
          sessionId: session.id,
        })).then(() => {
          sessionActionItems?.forEach(item => dispatch(removeDeletedActionItem({ connectionId, actionItemId: item.id })));
          dispatch(resetMentoringProgramSessionsList({ resetList: true }));
          onClose();
        });
      },
    }));
  };

  const optionItems: NvDropdownOption[] = [{
    type: 'text',
    class: 'text-danger',
    text: t.MENTORING_PROGRAMS.PARTICIPANT_HOME.PLAN_SESSION_FLYOUT.OPTIONS.DELETE_SESSION(),
    callback: deleteSession,
  }];

  const handleEdit = () => {
    setSaveStatus(SaveStatus.IN_PROGRESS);
    dispatch(updateMentoringProgramSession({
      ...session,
      programId: mentoringProgram.id,
      details: sessionDetails,
      sessionId: session.id,
    })).then(() => {
      setSaveStatus(SaveStatus.COMPLETED);
      dispatch(resetMentoringProgramSessionsList({ resetList: true }));
    }).finally(() => {
      setTimeout(() => {
        setSaveStatus(null);
      }, 3000);
    });
  };

  const handleChange = (value) => {
    setSessionDetails(value);
  };

  return (
    <>
      <div className='header'>
        <div>{t.MENTORING_PROGRAMS.PARTICIPANT_HOME.PLAN_SESSION_FLYOUT.HEADER()}</div>
        <div className='d-flex'>
          {saveStatus ? <SaveToast status={saveStatus} /> : null}
          <NvDropdown
            buttonStyle={NvDropdownButtonStyle.ICON}
            iconClass='icon-more icon-small mr-6 ml-4 text-gray-2'
            items={optionItems}
            toggleDataQa={dataQATag.sessionMenuDropdown}
          />
        </div>
      </div>
      <div className='content'>
        <ScheduleInfoCard session={session} onClick={() => setViewMode(false)} />
        <div className='session-action-items'>{t.MENTORING_PROGRAMS.PARTICIPANT_HOME.ACTION_ITEMS.TITLE()}</div>
        <div className='my-3' data-qa={dataQATag.actionItemsList} data-qa-id={`${dataQATag.actionItemsList}-${session?.id}`}>
          {[
            getDefaultActionItem(),
            ...sessionActionItems,
          ].map(
            (item) => item && (
              <ActionItem
                key={item.id}
                item={item}
                onAdd={onAdd}
                onUpdate={onUpdate}
                onDelete={onDelete}
                isEditing={editingId === item.id}
                setEditingId={setEditingId}
                connectionId={connectionId}
                enrollments={connectionEnrollments}
              />
            ),
          )}
        </div>
        <div className='session-details'>{t.MENTORING_PROGRAMS.PARTICIPANT_HOME.PLAN_SESSION_FLYOUT.SESSION_DETAILS()}</div>
        <div className='session-details-description'>{t.MENTORING_PROGRAMS.PARTICIPANT_HOME.PLAN_SESSION_FLYOUT.SESSION_DETAILS_DESCRIPTION()}</div>
        <NvAthenaFroala
          name='description'
          value={sessionDetails}
          onChange={handleChange}
          onBlur={handleEdit}
          withForm={false}
          resize={ResizeType.VERTICAL}
          placeholder={t.MENTORING_PROGRAMS.PARTICIPANT_HOME.PLAN_SESSION_FLYOUT.SESSION_DETAILS_PLACEHOLDER()}
          toolbarButtons={[
            ToolbarOptions.BOLD,
            ToolbarOptions.ITALIC,
            ToolbarOptions.UNDERLINE,
            ToolbarOptions.FORMAT_UL,
            ToolbarOptions.FORMAT_OL,
            ToolbarOptions.INSERT_LINK,
          ]}
        />
      </div>
    </>
  );
};

export default SessionViewMode;
