import { css } from '@emotion/react';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import NvRadioButton from 'shared/components/inputs/nv-radio-button';
import NvModal, { ModalType } from 'shared/components/nv-modal';
import { gray7, primary } from 'styles/global_defaults/colors';
import t from 'react-translate';
import { textLargeLineHeight } from 'styles/global_defaults/fonts';
import { largeSpacing, quarterSpacing } from 'styles/global_defaults/scaffolding';

export enum ApplyChangesOption {
  SINGLE_SESSION = 'SINGLE_SESSION',
  REMAINING_SESSIONS = 'REMAINING_SESSIONS',
}

const modalStyles = css`
  .bs4-modal-body {
    padding: ${largeSpacing}px !important;
    padding-top: 0 !important;
  }
`;

const styles = css`
  .selection {
    flex-grow: 1;
    aria-live: polite;
    .option {
      border-radius: ${quarterSpacing}px;
      background-color: ${gray7};
      label {
        cursor: pointer !important;
        line-height: ${textLargeLineHeight}px;
      }
      &.selected {
        border: 1px solid ${primary};
        border-radius: ${quarterSpacing}px;
        label {
          color: ${primary};
          ::before {
            border-color: ${primary};
            background-color: ${primary};
          }
        }
      }
    }

  }
`;

type ApplyChangesModalType = {
  show?: boolean;
  onClose: () => void;
  onSave: (option: ApplyChangesOption) => void;
};

const ApplyChangesModal = (props: ApplyChangesModalType) => {
  const { show = false, onClose, onSave } = props;
  const translations = t.MENTORING_PROGRAMS.PARTICIPANT_HOME.PLAN_SESSION_FLYOUT.EDIT_MODE.APPLY_CHANGES_MODAL;

  const methods = useFormContext();
  const { watch } = methods;
  const applyChangesOption = watch('applyChangesOption');

  return (
    <NvModal
      show={show}
      header={translations.HEADER()}
      modalStyles={modalStyles}
      height={336}
      width={536}
      type={ModalType.FIXED}
      onClose={() => onClose()}
      aria-labelledby='apply-changes-modal-title'
      doubleModal
      body={(
        <div css={styles} className='d-flex align-items-center flex-column w-100 h-100'>
          <h2 id='apply-changes-modal-title' className='sr-only'>{translations.HEADER()}</h2>
          <div className='selection w-100 d-flex flex-column justify-content-center'>
            {Object.values(ApplyChangesOption).map((option) => (
              <NvRadioButton
                withForm
                key={option}
                className={`p-3 w-100 mt-0 option ${applyChangesOption === option ? 'selected' : ''}`}
                value={option}
                name='applyChangesOption'
                label={translations[option]()}
                labelClassName='text-large-regular pl-6 mr-2'
              />
            ))}
          </div>
          <div className='buttons-row'>
            <Button variant='secondary' className='mr-2' onClick={onClose} size='lg' aria-label={translations.CANCEL()}>
              {translations.CANCEL()}
            </Button>
            <Button
              variant='primary'
              onClick={() => onSave(applyChangesOption)}
              size='lg'
              aria-label={translations.SAVE()}
              disabled={!applyChangesOption}
            >
              {translations.SAVE()}
            </Button>
          </div>
        </div>
      )}
    />
  );
};

export default ApplyChangesModal;
