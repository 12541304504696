import { useState } from 'react';
import t from 'react-translate';
import NvIcon from 'shared/components/nv-icon';
import NvTooltip from 'shared/components/nv-tooltip';
import { gray6 } from 'styles/global_defaults/colors';

const EditIconViewMode = () => {
  const [showEditTooltip, setShowEditTooltip] = useState(false);
  return (
    <div className='d-flex session-edit align-items-center justify-content-center'>
      <NvTooltip
        text={t.MENTORING_PROGRAMS.PARTICIPANT_HOME.PLAN_SESSION_FLYOUT.EDIT_SESSION_TOOLTIP()}
        placement='top'
        show={showEditTooltip}
        preventOverflow={false}
        zIndex={5092}
      >
        <div>
          <NvIcon
            icon='edit'
            size='small'
            color={gray6}
            className='p-2'
            onMouseOver={() => setShowEditTooltip(true)}
            onMouseOut={() => setShowEditTooltip(false)}
          />
        </div>
      </NvTooltip>
    </div>
  );
};

export default EditIconViewMode;
