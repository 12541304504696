import { useFormContext } from 'react-hook-form';
import { MentorshipProgramSession } from 'redux/schemas/models/mentoring-program-sessions';

type VirtualMeetingProps = {
  meetingLink: MentorshipProgramSession['meetingLink'];
  integrationType: MentorshipProgramSession['integrationType'];
  enabledIntegrations: string[]; // TODO: Set correct type for enabledIntegrations
};

const VirtualMeeting = (props: VirtualMeetingProps) => {
  const {
    meetingLink,
    integrationType,
    enabledIntegrations,
  } = props;

  // TODO: Implement in NOV-95451
  const { setValue } = useFormContext();

  // when choosing a virtual meeting, set the meeting link and integration type
  // setValue('meetingLink', meetingLink);
  // setValue('integrationType', integrationType);

  return (
    <div className='virtual-meeting'>
      VIRTUAL MEETING
    </div>
  );
};

export default VirtualMeeting;
