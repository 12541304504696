import { config } from '@config/pendo.config.json';
import moment from 'moment';

const DATE_FORMAT = 'MMMM D';

const getCadenceWarningModal = ({
  translations,
  onCancel,
  onConfirm,
  sessions: items,
}) => {
  const pendoTags = config.pendo.athena.mentorshipProgram.participantHome.sessions.planSessionFlyout.cadenceWarningModal;

  const sessions = items.map(({ id, title, scheduledDate }) => (
    <div key={id} className='session'>
      <div className='session-title'>{title} - {moment(scheduledDate).format(DATE_FORMAT)}</div>
    </div>
  ));

  const bodyText = (
    <div>
      {translations.DESCRIPTION_ABOVE()}
      <div className='sessions-container my-4'>{sessions}</div>
      {translations.DESCRIPTION_BELOW()}
    </div>
  );

  return {
    title: translations.TITLE(),
    bodyText,
    confirmText: translations.CONFIRM(),
    cancelText: translations.CANCEL(),
    cancelDataQa: pendoTags.cancel,
    confirmDataQa: pendoTags.confirm,
    cancelPendoTag: pendoTags.cancel,
    confirmPendoTag: pendoTags.confirm,
    onCancel,
    onConfirm,
  };
};

export default getCadenceWarningModal;
