import axios from 'axios';
import t from 'react-translate';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { addAlertMessage } from 'redux/actions/alert-messages';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import { MentoringProgramActionItem } from 'redux/schemas/models/mentoring-program-action-items';
import { ApplyChangesOption } from 'athena/components/mentoring-program/home/participant/session-edit-mode/apply-changes-modal';
import { getMentoringProgramEnrollment } from './mentoring-program-enrollments';

type CreateMentoringProgramSessionParams = {
  programId: number;
  enrollmentId: number;
  mentorshipProgramConnectionId: number;
  title: string;
  details?: string;
  scheduledDate: string;
  duration?: number;
  location?: string;
  cadence?: string;
  endsOn?: string;
  meetingLink?: string;
  integrationType?: string;
  timezone?: string;
  createMeetingPlan?: boolean;
};

enum MentoringProgramSessionType {
  SINGLE = 'mentorship_program_session',
  PLAN = 'mentorship_program_session_plan',
}

export const createMentoringProgramSession = createAsyncThunk(
  'CREATE_MENTORING_PROGRAM_SESSION',
  async ({
    programId,
    mentorshipProgramConnectionId,
    title,
    details,
    scheduledDate,
    duration,
    location,
    cadence,
    endsOn,
    meetingLink,
    integrationType,
    timezone,
    createMeetingPlan,
    enrollmentId,
  }: CreateMentoringProgramSessionParams, thunkAPI) => {
    const key = createMeetingPlan ? MentoringProgramSessionType.PLAN : MentoringProgramSessionType.SINGLE;
    const extraParams = createMeetingPlan ? {
      cadence,
      end_date: endsOn,
      start_date: scheduledDate,
    } : {
      scheduled_date: scheduledDate,
    };
    const response = await axios.post(
      `/mentorship_programs/${programId}/sessions`,
      {
        [key]: {
          title,
          details: details || '',
          duration,
          location,
          meeting_link: meetingLink,
          integration_type: integrationType,
          timezone,
          ...extraParams,
        },
        mentorship_program_connection_id: mentorshipProgramConnectionId,
      },
    );
    const message = createMeetingPlan
      ? t.MENTORING_PROGRAMS.PARTICIPANT_HOME.PLAN_SESSION_FLYOUT.CREATE_MEETING_PLAN_SUCCESS(response.data.result.length ?? 0)
      : t.MENTORING_PROGRAMS.PARTICIPANT_HOME.PLAN_SESSION_FLYOUT.ADD_SUCCESS_MESSAGE();

    thunkAPI.dispatch(addAlertMessage({
      type: AlertMessageType.SUCCESS,
      header: t.FORM.SUCCESS_BANG(),
      message,
    }));

    if (createMeetingPlan) {
      thunkAPI.dispatch(getMentoringProgramEnrollment({ programId, enrollmentId }));
    }

    return response.data.result;
  },
);

type GetMentoringProgramSession = {
  programId: number;
  sessionId: number;
};

export const getSingleMentoringProgramSession = createAsyncThunk(
  'GET_MENTORING_PROGRAM_SESSION',
  async ({ programId, sessionId }: GetMentoringProgramSession) => {
    const response = await axios.get(`/mentorship_programs/${programId}/sessions/${sessionId}.json`);

    return response.data.result;
  },
);

export const getMentoringProgramSessions = createAsyncThunk(
  'GET_MENTORING_PROGRAM_SESSIONS',
  async ({ programId, connectionId, page, pageSize, filter }: any) => {
    // TODO: talk with backend about changing these names so that we can simplify the types
    const params: any = {
      page,
      page_size: pageSize,
      status: filter,
    };

    if (filter === 'past') {
      params.scheduled_date_desc = true;
    } else {
      params.scheduled_date_asc = true;
    }

    params.mentorship_program_connection_id = connectionId;

    const response = await axios.get(`/mentorship_programs/${programId}/sessions`, { params });

    return response.data.result;
  },
);

export const updateMentoringProgramSession = createAsyncThunk(
  'UPDATE_MENTORING_PROGRAM_SESSIONS',
  async ({
    programId,
    sessionId,
    title,
    details,
    scheduledDate,
    duration,
    location,
    applyChangesOption,
    cadence,
    endsOn,
    meetingLink,
    integrationType,
    timezone,
  }: any, thunkAPI) => {
    let updateRemainingSessions;
    let meetingPlan = {};
    let message = t.MENTORING_PROGRAMS.PARTICIPANT_HOME.PLAN_SESSION_FLYOUT.UPDATE_SUCCESS_MESSAGE();
    if (applyChangesOption === ApplyChangesOption.REMAINING_SESSIONS) {
      updateRemainingSessions = true;
      meetingPlan = {
        [MentoringProgramSessionType.PLAN]: {
          cadence,
          end_date: endsOn,
          start_date: scheduledDate,
        },
      };
      message = t.MENTORING_PROGRAMS.PARTICIPANT_HOME.PLAN_SESSION_FLYOUT.UPDATE_MEETING_PLAN_SUCCESS();
    } else if (applyChangesOption === ApplyChangesOption.SINGLE_SESSION) {
      updateRemainingSessions = false;
    }

    const response = axios.put(`/mentorship_programs/${programId}/sessions/${sessionId}.json`, {
      [MentoringProgramSessionType.SINGLE]: {
        id: sessionId,
        title,
        details: details || '',
        duration,
        location,
        meeting_link: meetingLink,
        integration_type: integrationType,
        timezone,
        scheduled_date: scheduledDate,
      },
      ...meetingPlan,
      update_remaining_sessions: updateRemainingSessions || undefined,
    });

    thunkAPI.dispatch(addAlertMessage({
      type: AlertMessageType.SUCCESS,
      header: t.FORM.SUCCESS_BANG(),
      message,
    }));

    return (await response).data.result;
  },
);

export const deleteMentoringProgramSession = createAsyncThunk(
  'DELETE_MENTORING_PROGRAM_SESSION',
  async ({ programId, sessionId }: any) => {
    const response = await axios.delete(`/mentorship_programs/${programId}/sessions/${sessionId}.json`);

    return response.data.result;
  },
);

export const getMentoringProgramSessionsCounts = createAsyncThunk(
  'GET_MENTORING_PROGRAM_SESSIONS_COUNTS',
  async ({ programId, connectionId }: any) => {
    const params = {
      mentorship_program_connection_id: connectionId,
    };

    const response = await axios.get(`/mentorship_programs/${programId}/sessions/count.json`, { params });

    return response.data.result;
  },
);

export const resetMentoringProgramSessionsList = createAction<{
  resetList: boolean;
}>('RESET_MENTORING_PROGRAMS_SESSIONS_LIST');

export const addCreatedSessionActionItem = createAction<{
  sessionId: number;
  actionItem: MentoringProgramActionItem;
}>('ADD_CREATED_MENTORING_PROGRAM_SESSION_ACTION_ITEM');

export const updateModifiedSessionActionItem = createAction<{
  sessionId: number;
  actionItem: MentoringProgramActionItem;
}>('UPDATE_MODIFIED_MENTORING_PROGRAM_SESSION_ACTION_ITEM');

export const removeDeletedSessionActionItem = createAction<{
  sessionId: number;
  actionItemId: number;
}>('REMOVE_DELETED_MENTORING_PROGRAM_SESSION_ACTION_ITEM');

type FetchMeetingPlanParams = {
  mentorshipProgramSessionPlanId: number;
  programId: number;
  mentorshipProgramConnectionId: number;
};

export const fetchMeetingPlan = createAsyncThunk(
  'FETCH_MEETING_PLAN',
  async ({ mentorshipProgramSessionPlanId, programId }: FetchMeetingPlanParams) => {
    const response = await axios.get(`/mentorship_programs/${programId}/session_plans/${mentorshipProgramSessionPlanId}`);
    return response.data.result;
  },
);
